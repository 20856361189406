import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const MenubarSpacerStyles = (theme: Theme) => createStyles({
    root: {
        flex: "1 1 auto",
        height: 56,
        minHeight: 56,
        maxHeight: 56,
    },
});


type MenubarSpacerStyledProps = WithStyles<typeof MenubarSpacerStyles>;
export type MenubarSpacerCombinedProps = MenubarSpacerStyledProps;

function MenubarSpacer(props: MenubarSpacerCombinedProps) {

    const { classes } = props;

    return (
        <div className={classes.root}></div>
    );
}

export default withStyles(MenubarSpacerStyles, { withTheme: true })(MenubarSpacer);


// function Menubar