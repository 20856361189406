import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import moment, { dateHashFormat, getDateHashParts } from "../../tools/moment";
import { Tabs, Tab } from "@material-ui/core";
import { EventDay } from "../../model/response";

const EventDaysTabsStyles = (theme: Theme) => createStyles({
    tabsContainer: {
        height: 56,
    },
    tabsIndicator: {
        backgroundColor: theme.palette.common.white,
    },
});

export interface EventDaysTabsProps {
    days: EventDay[],
    tabsVariant?: "standard" | "scrollable" | "fullWidth",
    tabsScrollButtons?: "auto" | "desktop" | "on" | "off",
}

type EventDaysTabsStyledProps = WithStyles<typeof EventDaysTabsStyles>;
type EventDaysTabsRouterProps = RouteComponentProps<any>;
export type EventDaysTabsCombinedProps = EventDaysTabsProps & EventDaysTabsStyledProps & EventDaysTabsRouterProps;

function EventDaysTabs(props: EventDaysTabsCombinedProps) {

    const {
        classes, location, days,
        tabsVariant, tabsScrollButtons } = props;

    if (days.length === 0) {
        return <React.Fragment></React.Fragment>
    }

    const { day: hashDay } = getDateHashParts(location.hash);
    let daySelected = 0;
    days.forEach((d, idx) => {
        if (moment(d.date).format(`#${dateHashFormat}`) === hashDay) {
            daySelected = idx;
        }
    })

    return (
        <Tabs
            variant={tabsVariant}
            scrollButtons={tabsScrollButtons}
            value={daySelected}
            classes={{
                flexContainer: classes.tabsContainer,
                indicator: classes.tabsIndicator,
            }}
        >
            {
                days.map((d, idx) =>
                    <Tab
                        key={idx}
                        label={moment(d.date).format("dddd D")}
                        href={`#${moment(d.date).format(dateHashFormat)}`}
                    />
                )
            }
        </Tabs>
    );
}

export default withRouter(
    withStyles(EventDaysTabsStyles, { withTheme: true })(EventDaysTabs)
);