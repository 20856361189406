import { Guid } from "guid-typescript";

export default class Serializer {

    private static guidRegexp = /[0-9a-f]{7}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    private static dateRegexp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z?)/;

    public serialize = (data: any): string => {
        const d = JSON.stringify(data, this._jsonSerialize);
        return d;
    }

    private _jsonSerialize = (key: string, value: any): any => {
        if (key && value && value.value && Serializer.guidRegexp.test(value.value)) {
            return value.value;
        }
        return value;
    }

    public deserialize<TResponse = any>(data: string): TResponse {
        const d = JSON.parse(data, this._jsonDeserialize) as TResponse;
        return d;
    }

    private _jsonDeserialize = (key: string, value: any): any => {
        if (key && value && typeof key === 'string' && typeof value === 'string') {
            if (Serializer.guidRegexp.test(value)) {
                return Guid.parse(value)
            }
            if (Serializer.dateRegexp.test(value)) {
                return new Date(value)
            }
        }
        return value
    }

}