import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    Button as MuiButton,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const iconSpacingFactor = 7;

const ButtonIconStyles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        position: "relative",
    },
    button: {
        textTransform: "inherit",
        color: "inherit",
        padding: theme.spacing(1, 2, 1, 2),
    },
    buttonIconPrevious: {
        paddingLeft: theme.spacing(iconSpacingFactor),
    },
    buttonIconNext: {
        paddingRight: theme.spacing(iconSpacingFactor),
    },
    label: {

    },
    icon: {
        color: "inherit",
        width: theme.spacing(iconSpacingFactor),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    previousIcon: {
        left: 0,
    },
    nextIcon: {
        right: 0,
    },
});

export interface ButtonIconProps {
    leftIcon?: React.ReactElement<SvgIconProps>,
    rightIcon?: React.ReactElement<SvgIconProps>,
    className?: string,
    children?: any,
    href?: string,
    disabled?: boolean,
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void),
}

type ButtonIconStyledProps = WithStyles<typeof ButtonIconStyles>;
export type ButtonIconCombinedProps = ButtonIconStyledProps & ButtonIconProps;

function ButtonIcon(props: ButtonIconCombinedProps) {

    const { leftIcon: previousIcon, rightIcon: nextIcon, className, 
        onClick, href, disabled,
        children, classes } = props;

    const renderIcon = (
        icon: React.ReactElement<SvgIconProps> | undefined,
        className: string)
        : React.ReactElement | null => {

        if (icon) {
            return (
                <div className={classnames(classes.icon, className)}>
                    {icon}
                </div>
            )
        }
        return null;
    }

    const btnIconP = previousIcon ? classes.buttonIconPrevious : undefined;
    const btnIconN = nextIcon ? classes.buttonIconNext : undefined;

    return (
        <div className={classnames(classes.container, className)}>

            {renderIcon(previousIcon, classes.previousIcon)}

            <MuiButton
                onClick={onClick}
                href={href}
                disabled={disabled}
                classes={{
                    root: classnames(classes.button, btnIconN, btnIconP),
                    label: classes.label,
                }}
            >
                {children}
            </MuiButton>

            {renderIcon(nextIcon, classes.nextIcon)}

        </div>
    )
}

export default withStyles(ButtonIconStyles, { withTheme: true })(ButtonIcon)