import React from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    Drawer as MuiDrawer, Typography, Divider, ListItem, ListItemIcon, ListItemText, Avatar
} from "@material-ui/core"
import { EventsIcon, ActivitiesIcon, ContactIcon, CalendarIcon, DevicesIcon } from "../../App/App.theme";

import { routeFactory, RouteName } from "../../tools/route";
import Spacer from "../Spacer";
import moment from "../../tools/moment";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const DrawerStyles = (theme: Theme) => createStyles({
    drawer: {
        width: 260,
        flexShrink: 0,
        backgroundColor: theme.palette.primary.main,
    },
    paper: {
        width: 260,
    },
    header: {
        height: 180,
        minHeight: 180,
        maxHeight: 180,
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    link: {
        color: theme.palette.grey[800],
        "& svg": {
            color: theme.palette.grey[500],
        },
    },
    linkSelected: {
        color: theme.palette.primary.main,
        "& svg": {
            color: theme.palette.primary.main,
        },
    },
    avatar: {
        width: 60,
        height: 60,
        marginBottom: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark,
    },
    footer: {
        padding: theme.spacing(0, 1, 2, 3),
        display: "flex",
        flexDirection: "column",
        color: theme.palette.grey[700],
        "& > *": {
            margin: theme.spacing(0.5, 0),
        }
    },
    simpleLink: {
        textDecoration: "underline",
        color: "inherit",
    },
});

export interface DrawerProps {
    open: boolean,
    onClose: (() => void),
}

type RouterProps = RouteComponentProps<any>
type DrawerStyledProps = WithStyles<typeof DrawerStyles>;
type DrawerCombinedProps = DrawerProps & DrawerStyledProps & RouterProps;

interface DrawerRoute {
    title: string,
    routeName: RouteName,
    icon: React.ReactElement<SvgIconProps>,
}

function Drawer(props: DrawerCombinedProps) {

    const { open, onClose, classes } = props;

    const routes = [
        {
            title: "Fêtes",
            routeName: RouteName.TimelineEvents,
            icon: <EventsIcon />,
        },
        {
            title: "Activités",
            routeName: RouteName.TimelineActivities,
            icon: <ActivitiesIcon />,
        },
        {
            title: "Contact",
            routeName: RouteName.Contact,
            icon: <ContactIcon />,
        },
    ] as DrawerRoute[];

    const today = moment().format("dddd Do MMMM");

    const { pathname } = props.location;

    return (
        <nav className={classes.drawer}>
            <MuiDrawer
                closeAfterTransition={true}
                open={open}
                anchor="left"
                variant="temporary"
                classes={{ paper: classes.paper }}
                onClose={onClose}
                onClick={onClose}
            >
                <div 
                    className={classes.header}
                >
                    <Avatar
                        alt="logo"
                        className={classes.avatar}
                    >
                        <CalendarIcon />
                    </Avatar>
                    <Spacer />
                    <Typography variant="caption" component="span">{today}</Typography>
                    <Typography variant="h6" component="h3">Ferias40</Typography>
                </div>

                <Divider />

                {
                    routes.map((r, idx) => {
                        const link = routeFactory.fullPath(r.routeName);
                        const selected = link === pathname;
                        return (
                            <Link
                                key={idx}
                                to={link}
                                className={classes.link}
                            >
                                <ListItem
                                    button
                                    selected={selected}
                                    classes={{
                                        selected: classes.linkSelected
                                    }}
                                >
                                    <ListItemIcon>{r.icon}</ListItemIcon>
                                    <ListItemText primary={r.title} />
                                </ListItem>
                            </Link>
                        );
                    })
                }

                <Spacer />

                <div className={classes.footer}>
                    <a 
                        href={routeFactory.fullPath(RouteName.Facebook)} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className={classes.simpleLink}
                        >
                        Facebook
                    </a>

                    <Link 
                        to={routeFactory.fullPath(RouteName.Cgu)}
                        className={classes.simpleLink}
                        >
                        CGU et mentions légales
                    </Link>

                    <DevicesIcon />
                </div>

            </MuiDrawer>
        </nav>
    );
}

export default withRouter(
    withStyles(DrawerStyles, { withTheme: true })(Drawer)
);