import axios, { AxiosRequestConfig } from "axios";

import ApiRequest from "./ApiRequest";
import Serializer from "../Serializer";

declare var API_BASE: any;

export default class ApiFetch {

    _serializer: Serializer;

    constructor() {
        this._serializer = new Serializer();
    }

    private _buildAxiosRequest(request: ApiRequest): AxiosRequestConfig {
        const url = request._path + (request._query ? '?' + request._query : '')
        let axiosRequest = ({
            baseURL: API_BASE,
            url: url,
            method: request._method,
            headers: {
                'Content-Type': 'application/json'
            },
        }) as AxiosRequestConfig

        if (request._isBody) {
            axiosRequest.data = this._serializer.serialize(request._body)
        }

        return axiosRequest
    }


    public async axiosFetch<TResponse = any>(request: ApiRequest): Promise<TResponse> {
        let axiosRequest = this._buildAxiosRequest(request);
        const response = await axios.request<TResponse>(axiosRequest);
        return response.data;
    }

    public async axiosFetchData<TResponse>(request: ApiRequest): Promise<TResponse> {
        const response = await this.axiosFetch<any>(request);
        const data = this._serializer.deserialize<TResponse>(JSON.stringify(response));
        return data;
    }

}
