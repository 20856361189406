import ApiRequestName from "./ApiRequestName";
import ApiRequest, { ApiRequestMethod } from "./ApiRequest";

export default class ApiRequestConfiguration {

    requests: ApiRequest[];

    constructor() {
        this.requests = [];
    }

    public get(name: ApiRequestName,
        path: string,
        isParameters: boolean = true,
        isBody: boolean = false): ApiRequestConfiguration {

        return this._add(name, "GET", path, isParameters, isBody);
    }

    public post(name: ApiRequestName,
        path: string,
        isParameters: boolean = false,
        isBody: boolean = true): ApiRequestConfiguration {

        return this._add(name, "POST", path, isParameters, isBody);
    }

    public put(name: ApiRequestName,
        path: string,
        isParameters: boolean = true,
        isBody: boolean = true): ApiRequestConfiguration {

        return this._add(name, "PUT", path, isParameters, isBody);
    }

    public delete(name: ApiRequestName,
        path: string,
        isParameters: boolean = true,
        isBody: boolean = false): ApiRequestConfiguration {

        return this._add(name, "DELETE", path, isParameters, isBody);
    }

    private _add(name: ApiRequestName,
        method: ApiRequestMethod,
        path: string,
        isParameters: boolean,
        isBody: boolean): ApiRequestConfiguration {

        const request = new ApiRequest(name, method, path, isParameters, isBody);
        const rName = request.name();
        const sameNameRequest = this.requests.find(x => x.name() === rName);
        if (sameNameRequest !== undefined) {
            throw Error(`Another request exists with same name ${name}}`);
        }

        this.requests.push(request);
        return this;
    }
}

