import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const MenubarSpacerMobileStyles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flex: "1 1 auto",
        height: 56,
        minHeight: 56,
        maxHeight: 56,
    },
});


type MenubarSpacerMobileStyledProps = WithStyles<typeof MenubarSpacerMobileStyles>;
export type MenubarSpacerMobileCombinedProps = MenubarSpacerMobileStyledProps;

function MenubarSpacerMobile(props: MenubarSpacerMobileCombinedProps) {

    const { classes } = props;

    return (
        <div className={classes.root}></div>
    );
}

export default withStyles(MenubarSpacerMobileStyles, { withTheme: true })(MenubarSpacerMobile);


// function Menubar