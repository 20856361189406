enum RouteName {
    TimelineEvents,
    TimelineActivities,
    EventProgram,
    Contact,
    Cgu,
    Facebook,

    DepreciatedEventProgram,
}
export default RouteName;

export function toString(name: RouteName): string {
    return RouteName[name];
}