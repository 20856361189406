import React from "react";

import { IconButton, Typography } from "@material-ui/core";

import { ToolbarMobile as CToolbarMobile } from "../../components/Toolbar";
import { MenuIcon } from "../../App";

export interface Props {
    onOpenDrawer: (() => void),
}

function ToolbarMobile(props:Props) {
    
    const { onOpenDrawer } = props;

    return (
        <CToolbarMobile>

            <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onOpenDrawer}
            >
                <MenuIcon />
            </IconButton>

            <Typography variant="h6" component="h3">
                Contact
            </Typography>

        </CToolbarMobile>
    );
}

export default ToolbarMobile;
