import React from "react";
import classnames from "classnames";
import { withRouter, RouteComponentProps } from "react-router";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    IconButton, CircularProgress,
} from "@material-ui/core";


import { ToolbarMobile as CToolbarMobile } from "../../components/Toolbar";
import Spacer from "../../components/Spacer";
import ButtonIcon from "../../components/ButtonIcon";
import {
    MenuIcon, SearchIcon,
    ArrowDownIcon,
    ArrowLeftIcon,
} from "../../App/App.theme";

import { Event } from "../../model/response";
import { SearchEventInput, SearchEventResults } from "../../components/SearchEvent";

const Styles = (theme: Theme) => createStyles({
    monthBtn: {
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: fade(theme.palette.common.white, 0.90),
        textTransform: "capitalize",
        fontSize: theme.typography.h6.fontSize,
        width: "auto",
        "&:hover": {
            color: fade(theme.palette.common.white, 1),
        },
    },
    searchToolbar: {
        overflow: "visible",
    },
    hidden: {
        display: "none",
    },
    searchContainer: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: fade(theme.palette.common.black, 0.40),
        zIndex: theme.zIndex.modal,
        padding: theme.spacing(1.25, 1),
    },
    searchInputContainer: {
        backgroundColor: fade(theme.palette.background.default, 1),
    },
    searchInputInput: {
        width: "100%",
        display: "flex",
        flex: "1 1 auto",
    },
    progressIcon: {
        maxWidth: theme.spacing(3),
        width: theme.spacing(3),
        maxHeight: theme.spacing(3),
        height: theme.spacing(3),
    },
    resultsContainer: {
        marginTop: theme.spacing(2),
        width: "100%",
        display: "block",
        maxHeight: "calc(100% - 56px)",
        overflowY: "auto",
    },
})

const SEARCH_CONTAINER_CLASS = "search-container-background";

export interface Props {
    onOpenDrawer: (() => void),
    onDatePicker: (() => void),
    datepickerLabel: string,
    onSearchEvent: ((search: string) => void),
    searchEventLoading: boolean,
    searchEventResults: Event[] | undefined,
}

type StyledProps = WithStyles<typeof Styles>;
type RouterProps = RouteComponentProps<any>;
export type CombinedProps = Props & StyledProps & RouterProps;

export interface State {
    searchFocused: boolean,
}

class ToolbarMobile extends React.Component<CombinedProps, State> {

    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: CombinedProps) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            searchFocused: false,
        }
    }

    handleDesactiveSearch = () => {
        this.setState({ searchFocused: false });
    }

    handleActiveSearch = () => {
        this.setState({ searchFocused: true }, () => {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }
        });
    }

    render() {
        const {
            classes, onDatePicker, onOpenDrawer, datepickerLabel,
            searchEventLoading, onSearchEvent, searchEventResults,
        } = this.props;

        const { searchFocused } = this.state;

        const rightIcon = searchEventLoading
            ? <CircularProgress className={classes.progressIcon} />
            : <SearchIcon />;
        const clearable = !searchEventLoading;

        const focusedClass = searchFocused ? undefined : classes.hidden;
     
        return (
            <React.Fragment>

                <CToolbarMobile>

                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={onOpenDrawer}
                    >
                        <MenuIcon />
                    </IconButton>

                    <ButtonIcon
                        rightIcon={<ArrowDownIcon />}
                        onClick={onDatePicker}
                        classes={{ button: classes.monthBtn }}
                    >
                        {datepickerLabel}
                    </ButtonIcon>

                    <Spacer />

                    <IconButton
                        color="inherit"
                        aria-label="Search event"
                        onClick={this.handleActiveSearch}
                    >
                        <SearchIcon />
                    </IconButton>

                </CToolbarMobile>

                <div
                    className={classnames(focusedClass, classes.searchContainer, SEARCH_CONTAINER_CLASS)}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        const target = e.nativeEvent.target as HTMLDivElement | null;
                        if (target && target.classList.contains(SEARCH_CONTAINER_CLASS)) {
                            this.handleDesactiveSearch();
                        }
                    }}
                >

                    <SearchEventInput
                        inputRef={this.inputRef}
                        leftIcon={<ArrowLeftIcon />}
                        onClickLeftIcon={this.handleDesactiveSearch}
                        rightIcon={rightIcon}
                        clearable={clearable}
                        onChange={onSearchEvent}
                        classes={{
                            container: classes.searchInputContainer,
                            inputInput: classes.searchInputInput,
                        }}
                    />

                    {
                        searchEventResults !== undefined
                            ?
                            <SearchEventResults
                                events={searchEventResults}
                                classes={{
                                    container: classes.resultsContainer,
                                }}
                            />
                            :
                            null
                    }

                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(
    withStyles(Styles, { withTheme: true })(ToolbarMobile)
);
