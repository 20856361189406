import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const SpacerStyles = (theme: Theme) => createStyles({
    container: {
        flex: "1 1 auto",
    }
});

export interface SpacerProps {
    className?: string,
}

type SpacerStyledProps = WithStyles<typeof SpacerStyles>;
export type SpacerCombinedProps = SpacerProps & SpacerStyledProps;

function Spacer(props: SpacerCombinedProps) {

    const { classes, className } = props;

    return <div className={classnames(classes.container, className)}></div>
}

export default withStyles(SpacerStyles, { withTheme: true })(Spacer);
