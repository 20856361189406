import RouteName, { toString } from "./RouteName";
import Route from "./Route";
import RouteConfiguration from "./RouteConfiguration";

export default class RouteFactory {

    routes:Route[];

    constructor(config: RouteConfiguration) {
        this.routes = config.routes;
    }

    public get(name: RouteName): Route {
        const rName = toString(name);
        const route = this.routes.find(r => r.name() === rName);

        if (route) {
            return route.clone();
        }

        throw Error(`undefined Route named "${rName}"`);
    }

    public is(name: RouteName, pathname: string): boolean {
        return this.fullPath(name) === pathname;
    }

    public fullPath(name: RouteName): string {
        const route = this.get(name);
        return route.fullPath();
    }

}