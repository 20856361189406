import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const TimelineRowStyles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        position: "relative",
        backgroundColor: theme.palette.background.default,
    },
    leftContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        position: "relative",
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        minHeight: 40,
        padding: theme.spacing(2, 1, 1, 0.5),
        backgroundColor: "inherit",
    },
    separator: {
        borderRightColor: theme.palette.primary.main,
        borderRightStyle: "solid",
        borderRightWidth: 3,
        flex: "0 1 auto",
        position: "relative",
        zIndex: 50,
        backgroundColor: "inherit",
    },
    rightContainer: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        flexWrap: "wrap",
        position: "relative",
        padding: theme.spacing(2, 1, 1, 2),
        backgroundColor: "inherit",
    },
    chip: {
        position: "absolute",
        width: 16,
        height: 16,
        top: 0,
        right: 0,
        marginTop: 30,
        marginRight: -9,
        zIndex: 100,
        backgroundColor: "inherit",
    },
    chipCircle: {
        borderColor: theme.palette.primary.main,
        borderStyle: "solid",
        borderWidth: 4,
        borderRadius: "50%",
    },
    chipSvg: {
        color: theme.palette.primary.main,
        "& svg": {
            marginTop: -4,
            marginLeft: -3,
        }
    },
});

interface TimelineRowProps {
    id?: string,
    className?: string,
    leftComponent?: React.ReactElement,
    chip?: boolean | React.ReactElement<SvgIconProps>,
    children?: React.ReactElement | React.ReactElement[],
}

type TimelineRowStyledProps = WithStyles<typeof TimelineRowStyles>;
export type TimelineRowCombinedProps = TimelineRowProps & TimelineRowStyledProps;

function TimelineRow(props: TimelineRowCombinedProps) {

    const { classes, id, className, leftComponent, chip, children } = props;

    const renderSepratorChip = (): React.ReactElement | null => {
        if (chip === false || chip === undefined) return null;

        if (chip === true) {
            return <span className={classnames(classes.chip, classes.chipCircle)}></span>
        }
        else {
            return <span className={classnames(classes.chip, classes.chipSvg)}>{chip}</span>
        }
    }

    return (
        <div id={id} className={classnames(className, classes.root)}>

            <div className={classes.leftContainer}>
                {leftComponent}
            </div>

            <div className={classes.separator}>
                {renderSepratorChip()}
            </div>

            <div className={classes.rightContainer}>
                {children ? children : null}
            </div>

        </div>
    )
}

export default withStyles(TimelineRowStyles, { withTheme: true })(TimelineRow);