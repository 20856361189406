import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";
import { ClockOffIcon } from "../../App";
import TimelineRow from "./TimelineRow";

const TimelineTimeEndRowStyles = (theme: Theme) => createStyles({
    rowRoot: {
        marginBottom: theme.spacing(6),
    },
    sideContainer: {
        minHeight: 20,
        maxHeight: 20,
        height: 20,
    },
    chipSvg: {
        marginTop: 24,
    },
    timeEnd: {
        position: "relative",
        top: 0,
        left: 0,
        marginLeft: theme.spacing(2),
    },
});

interface TimelineTimeEndRowProps {
    time: string,
}

type TimelineTimeEndRowStyledProps = WithStyles<typeof TimelineTimeEndRowStyles>;
export type TimelineTimeEndRowCombinedProps = TimelineTimeEndRowProps & TimelineTimeEndRowStyledProps;

function TimelineTimeEndRow(props: TimelineTimeEndRowCombinedProps) {
    const { time, classes } = props;

    const parts = time.split(":");
    const time_hour   = parseInt(parts[0], 10).toString();
    let time_minute = parseInt(parts[1], 10).toString();
    time_minute = time_minute.length === 1 ? `${time_minute}0` : time_minute;

    return (
        <TimelineRow
            chip={<ClockOffIcon />}
            classes={{
                root: classes.rowRoot,
                leftContainer: classes.sideContainer,
                rightContainer: classes.sideContainer,
                chipSvg: classes.chipSvg,
            }}
        >

            <Typography variant="h6" color="primary" className={classes.timeEnd}>
                Fermeture à {time_hour}h{time_minute}
            </Typography>

        </TimelineRow>
    )
}

export default withStyles(TimelineTimeEndRowStyles, { withTheme: true })(TimelineTimeEndRow);