import RouteName from "./RouteName";
import Route from "./Route";

export default class RouteConfiguration {
    routes: Route[];

    constructor() {
        this.routes = [];
    }

    public add(name: RouteName, path: string): RouteConfiguration {
        this._add(new Route(name, path));
        return this;
    }

    private _add(route: Route): void {
        const name = route.name();
        const sameNameRoute = this.routes.find(x => x.name() === name);
        if (sameNameRoute !== undefined) {
            throw Error(`Another route exists with same name ${name} and path ${sameNameRoute.fullPath()}`);
        }

        const path = route.fullPath();
        const samePathRoute = this.routes.find(x => x.fullPath() === path);
        if (samePathRoute !== undefined) {
            throw Error(`Another route exists with same path ${path} and name ${samePathRoute.name()}`);
        }

        this.routes.push(route);
    }
}
