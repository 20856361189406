import ApiRequestName, { toString } from "./ApiRequestName";
import ApiFetch from "./ApiFetch";

export type ApiRequestMethod = "GET" | "POST" | "PUT" | "DELETE";

declare var API_BASE: any;

if (API_BASE === "{{API_BASE}}") {
    API_BASE = "https://localhost:5000";
}

export default class ApiRequest {
    _name: ApiRequestName;
    _method: ApiRequestMethod;
    _path: string;
    _query: string;
    _isParameters: boolean;
    _isBody: boolean;

    _body?: any;

    constructor(
        name: ApiRequestName,
        method: ApiRequestMethod,
        path: string,
        isParameters: boolean = false,
        isBody: boolean = false) {

        this._name = name;
        this._method = method;
        this._path = path;
        this._query = "";
        this._isParameters = isParameters;
        this._isBody = isBody;
    }

    public name(): string {
        return toString(this._name);
    }

    public fullPath(): string {
        return API_BASE + this._path;
    }

    public clone(): ApiRequest {
        return new ApiRequest(this._name, this._method,
            this._path, this._isParameters, this._isBody);
    }

    public isPathContainsParameters(): boolean {
        return this._path.indexOf(":") !== -1;
    }

    public setQuery(query: string): ApiRequest {
        this._query = query;
        return this;
    }

    public setParameters(params: { [key: string]: string }): ApiRequest {
        if (!this._isParameters) {
            throw new Error(`ApiRequest "${this.name()}" doesn"t need any parameters`);
        }
        Object.keys(params).forEach(k => {
            const id = ":" + k;
            const value = params[k];
            this._path = this._path.replace(id, value);
        })
        return this;
    }

    public setBody(body: any): ApiRequest {
        if (!this._isBody) {
            throw new Error(`ApiRequest "${this.name()}" doesn"t need body`);
        }
        this._body = body;
        return this;
    }

    public assert(): void {
        if (this.isPathContainsParameters()) {
            throw new Error(`Some parameters of ApiRequest "${this.name()}" has not been set, path : "${this._path}"`);
        }
        if (this._isBody && (this._body === undefined || this._body === "")) {
            throw new Error(`ApiRequest "${this.name()}" must have a body`);
        }
    }

    public request<TResponse = any>() {
        this.assert();
        const fetch = new ApiFetch();
        return fetch.axiosFetch(this);
    }

    public requestData<TResponse>() {
        this.assert();
        const fetch = new ApiFetch();
        return fetch.axiosFetchData<TResponse>(this);
    }
}
