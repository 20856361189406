import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import HideOnScroll from "../HideOnScroll";
import Container from "../Container";
import { Typography } from "@material-ui/core";

const MenubarDesktopStyles = (theme: Theme) => createStyles({
    containerRoot: {
        display: "flex",
        flex: "1 1 auto",
        backgroundColor: theme.palette.primary.dark,
        padding: 0,
    },
    containerContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
    rowHeader: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        height: 56,
        maxHeight: 56,
        minHeight: 56,
        padding: theme.spacing(0.5, 0),
        overflow: "hidden",
    },
    rowCollapse: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        height: 56,
        maxHeight: 56,
        minHeight: 56,
        padding: theme.spacing(1, 0),
        overflow: "hidden",
    },
    title: {
        marginTop: 8,
    },
    subtitle: {
        marginTop: -6,
        "&::first-letter": {
            textTransform: "capitalize",
        },
    },
});

export interface MenubarDesktopProps {
    title: string,
    subtitle?: string,
    children?: React.ReactElement | (React.ReactElement)[],
    collapseHidden?: boolean,
}

type MenubarDesktopStyledProps = WithStyles<typeof MenubarDesktopStyles>;
export type MenubarDesktopCombinedProps = MenubarDesktopProps & MenubarDesktopStyledProps;

function MenubarDesktop(props: MenubarDesktopCombinedProps) {

    const { classes, title, subtitle, children, collapseHidden } = props;

    const titleAloneClass = subtitle ? undefined : classes.title;

    return (

        <Container
            classes={{
                root: classes.containerRoot,
                container: classes.containerContainer
            }}
        >
            <div className={classes.rowHeader}>
                <Typography variant="h6" component="h1" className={classnames(titleAloneClass)}>
                    {title}
                </Typography>
                {
                    subtitle ?
                        <Typography variant="body2" component="h2" className={classes.subtitle}>
                            {subtitle}
                        </Typography>
                        : null
                }
            </div>

            {children
                ?
                <HideOnScroll hidden={collapseHidden}>
                    <div className={classes.rowCollapse}>
                        {children}
                    </div>
                </HideOnScroll>
                :
                <React.Fragment></React.Fragment>
            }

        </Container>

    );
}

export default withStyles(MenubarDesktopStyles, { withTheme: true })(MenubarDesktop);
