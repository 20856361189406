import ApiRequestName, { toString } from "./ApiRequestName";
import ApiRequest from "./ApiRequest";
import ApiRequestConfiguration from "./ApiRequestConfiguration";

export default class ApiRequestFactory {

    requests: ApiRequest[];

    constructor(config: ApiRequestConfiguration) {
        this.requests = config.requests;
    }

    public get(name: ApiRequestName): ApiRequest {
        const rName = toString(name);
        const request = this.requests.find(r => r.name() === rName);
        if (request) {
            return request.clone();
        }
        throw new Error(`undefined ApiRequest named "${rName}"`);
    }
}

