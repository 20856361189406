import React from "react";

import TimelineEventsPage from "./TimelineEventsPage";
import { apiRequestFactory, ApiRequestName } from "../../tools/api";
import moment, { numberOfDaysBetween } from "../../tools/moment";

import { DayEvents, Event, Month } from "../../model/response";
import {
    sessionStorageCacheAsync,
    StorageName,
} from "../../tools/storage";

export interface Props {
    onOpenDrawer: (() => void),
}

export interface State {
    timelineDays: Date[],
    timelineMonths: Month[],
    timelineLoading: boolean,
    timelineEvents: DayEvents[],
    searchEventLoading: boolean,
    searchEventResults: Event[] | undefined,
}

class TimelineEventsPageContainer extends React.Component<Props, State> {

    _requestFetched: boolean;

    constructor(props: Props) {
        super(props);

        this._requestFetched = false;

        this.state = {
            timelineDays: [],
            timelineMonths: [],
            timelineLoading: true,
            timelineEvents: [],
            searchEventLoading: false,
            searchEventResults: undefined,
        }
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.onScroll);

        await this.loadTimelineDays();
        await this.loadTimelineMonths();
        this.updateTimelineEvents();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        const delta = 150;
        const scrollY = window.scrollY;
        const scrollMax = document.body.clientHeight - window.innerHeight;
        if (scrollY + delta >= scrollMax) {
            this.updateTimelineEvents();
        }
    }

    loadTimelineDays = async () => {
        try {
            let timelineDays = await sessionStorageCacheAsync<Date[]>(
                StorageName.TimelineDays,
                () => apiRequestFactory
                    .get(ApiRequestName.ListTimelineDays)
                    .requestData<Date[]>()
            );
            // timelineDays = timelineDays.map(x => new Date(x));
            this.setState({ timelineDays });
        }
        catch (e) {
            this.setState({ timelineDays: [] });
        }
    }

    loadTimelineMonths = async () => {
        try {
            const timelineMonths = await sessionStorageCacheAsync<Month[]>(
                StorageName.TimelineMonths,
                () => apiRequestFactory
                    .get(ApiRequestName.ListTimelineMonths)
                    .requestData<Month[]>()
            );
            this.setState({ timelineMonths });
        }
        catch (e) {
            this.setState({ timelineMonths: [] });
        }
    }

    updateTimelineEvents = async (top: number = 0) => {
        try {
            let { timelineEvents, timelineLoading } = this.state;

            if (this._requestFetched || !timelineLoading) {
                return;
            }

            this._requestFetched = true;

            top += 12;
            const nextDate = this.getTimelineEventsNextDate();
            const query = `startAt=${nextDate.toISOString()}&top=${top}`;

            const newDayEvents = await apiRequestFactory
                .get(ApiRequestName.TimelineEvents)
                .setQuery(query)
                .requestData() as DayEvents[];

            timelineEvents.push(...newDayEvents);
            timelineLoading = newDayEvents.length === top;

            this.setState({ timelineEvents, timelineLoading });
            
            this._requestFetched = false;
        }
        catch (e) {
            console.error(e);
        }
    }

    getTimelineEventsNextDate = (): Date => {
        const { timelineEvents } = this.state;
        let nextDate = moment();
        const nbEvents = timelineEvents.length;
        if (nbEvents > 0) {
            nextDate = moment(timelineEvents[nbEvents - 1].date)
                .add(1, "days");
        }
        nextDate = nextDate.startOf("day"); // remove time
        return nextDate.toDate();
    }

    handleUpdateTimelineEventsTo = async (toDate: Date) => {
        const { timelineDays } = this.state;
        const nextDate = this.getTimelineEventsNextDate();

        const mNext = moment(nextDate);
        const mTo = moment(toDate);

        let top = numberOfDaysBetween(mNext, mTo);
        if (timelineDays.length > 0) {
            top = timelineDays.filter(x => moment(x).isBetween(mNext, mTo)).length + 1;
        }

        await this.updateTimelineEvents(top);
    }

    handleSearchEvent = async (keywords: string) => {
        if (!keywords || keywords.length === 0) {
            this.setState({
                searchEventLoading: false,
                searchEventResults: undefined,
            });
            return;
        }

        this.setState({ searchEventLoading: true });

        try {
            const events = await apiRequestFactory
                .get(ApiRequestName.SearchEvents)
                .setQuery(`keywords=${keywords}`)
                .requestData() as Event[];
            this.setState({
                searchEventLoading: false,
                searchEventResults: events,
            });
        }
        catch (e) {
            console.error(e);
            this.setState({
                searchEventLoading: false,
                searchEventResults: [],
            });
        }
    }

    render() {
        const { onOpenDrawer } = this.props;
        const {
            timelineDays, timelineMonths,
            timelineLoading, timelineEvents,
            searchEventLoading, searchEventResults,
        } = this.state;

        return (
            <TimelineEventsPage
                onOpenDrawer={onOpenDrawer}

                timelineMonths={timelineMonths}

                timelineEvents={timelineEvents}
                timelineLoading={timelineLoading}
                onUpdateTimelineTo={this.handleUpdateTimelineEventsTo}

                datepickerDays={timelineDays}

                searchEventLoading={searchEventLoading}
                searchEventResults={searchEventResults}
                onSearchEvent={this.handleSearchEvent}
            />
        );
    }
}

export default TimelineEventsPageContainer;