import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography, CircularProgress } from "@material-ui/core";

import EventDaysTabs from "./EventDaysTabs";
import { MenubarMobile as CMenubarMobile } from "../../components/Menubar";
import { ErrorIcon } from "../../App";
import { Event } from "../../model/response";
import { getDatesRangeLabel } from "../../tools/moment";

const Styles = (theme: Theme) => createStyles({
    menubarRowCollapse: {
        flexDirection: "column",
    },
    menubarRowCenter: {
        flexDirection: "row",
        alignItems: "center",
    },
    loading: {
        marginLeft: "auto",
        marginRight: "auto",
        "& svg": {
            color: theme.palette.background.default,
        },
    },
    datesUnvalide: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: theme.spacing(2),
        "& svg": {
            marginRight: theme.spacing(1),
        },
        "& span::first-letter": {
            textTransform: "capitalize",
        }
    },
});

export interface Props {
    loading: boolean,
    eventProgram?: Event,
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function MenubarMobile(props: CombinedProps) {

    const { classes, loading, eventProgram } = props;

    let subTitle = undefined;

    if (!loading && eventProgram) {
        subTitle = getDatesRangeLabel(eventProgram.startAt, eventProgram.endAt, true);
    }

    const areDatesValidated = eventProgram ? eventProgram.areDatesValidated : true;

    let centerClass = undefined as string | undefined;
    if (loading || !areDatesValidated) {
        centerClass = classes.menubarRowCenter;
    }

    return (
        <CMenubarMobile
            classes={{
                rowCollapse: classnames(classes.menubarRowCollapse, centerClass),
            }}
        >
            {loading
                ?
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
                :
                <React.Fragment></React.Fragment>
            }

            {!loading && !areDatesValidated
                ?
                <Typography variant="body2" className={classes.datesUnvalide}>
                    <ErrorIcon />
                    <span>
                        {subTitle}
                    </span>
                </Typography>
                :
                <React.Fragment></React.Fragment>
            }

            {!loading && areDatesValidated
                ?
                <EventDaysTabs
                    days={eventProgram ? eventProgram.days : []}
                    tabsVariant="scrollable"
                    tabsScrollButtons="on"
                />
                :
                <React.Fragment></React.Fragment>
            }

        </CMenubarMobile>
    );
}

export default withStyles(Styles, { withTheme: true })(MenubarMobile);