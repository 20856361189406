import React from "react";

import ContactPage from "./ContactPage";

export interface Props {
    onOpenDrawer: (() => void),
}

function ContactPageContainer(props:any) {

    const { onOpenDrawer } = props;

    return (
        <ContactPage 
            onOpenDrawer={onOpenDrawer}
        />
    );
}

export default ContactPageContainer;