import React from "react";

import {
    useScrollTrigger, Collapse,
} from "@material-ui/core";

interface HideOnScrollProps {
    collapsedHeight?: number,
    hidden?: boolean,
    onEnter?: (() => void),
    onExit?: (() => void),
    children: React.ReactElement | React.ReactElement[];
}

function HideOnScroll(props: HideOnScrollProps) {
    const { hidden, children, collapsedHeight, onEnter, onExit } = props;

    const trigger = useScrollTrigger({
        threshold: 80,
    });

    if (hidden === true) return null;

    return (
        <Collapse in={!trigger} collapsedHeight={collapsedHeight ? `${collapsedHeight}px` : undefined}
            onEnter={onEnter}
            onExit={onExit}
        >
            {children}
        </ Collapse>
    );
}

export default HideOnScroll;
