import { routeFactory, RouteName } from "./route";

class History {

    _pages: string[];

    constructor() {
        this._pages = [];
    }

    pushPage(pathname:string) {
        this._pages.push(pathname);
    }

    getPreviousProgram(): string {
        let page = undefined as string | undefined;
        const eventsPage = routeFactory.fullPath(RouteName.TimelineEvents);
        const activitiesPage = routeFactory.fullPath(RouteName.TimelineActivities);
        for(let i=this._pages.length-1 ; i >=0 ; i--) 
        {
            const p = this._pages[i];
            if (p === eventsPage || p === activitiesPage)
            {
                page = p;
                break;
            }
        }

        return page ? page : eventsPage;
    }
}

const history = new History();

export { history };