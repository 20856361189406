import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const ToolbarSpacerStyles = (theme: Theme) => createStyles({
  container: {
    flex: "1 1 auto",
    width: "100%",
    padding: 0,
    height: 60,
    minHeight: 60,
    maxHeight: 60,
  }
})

type ToolbarSpacerStyledProps = WithStyles<typeof ToolbarSpacerStyles>;
export type ToolbarSpacerCombinedProps = ToolbarSpacerStyledProps;

function ToolbarSpacer(props:ToolbarSpacerCombinedProps) {

    const { classes } = props;

    return <div className={classes.container}></div>
  }

export default withStyles(ToolbarSpacerStyles, { withTheme: true })(ToolbarSpacer);
