import React from "react";
import classnames from "classnames";
import { withRouter, RouteComponentProps } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    ListItem, List, ListItemText,
    Typography, Paper
} from "@material-ui/core";

import { Event } from "../../model/response";
import { routeFactory, RouteName } from "../../tools/route";
import { getDatesRangeLabel } from "../../tools/moment";
import { ErrorIcon } from "../../App";

const SearchEventResultsStyles = (theme: Theme) => createStyles({
    container: {
        boxShadow: theme.shadows[10],
        zIndex: theme.zIndex.modal,
        overflow: "hidden",
        overflowY: "auto",
    },
    item: {
        paddgin: theme.spacing(0.5, 2),
    },
    title: {
        display: "block",
        color: theme.palette.text.primary,
    },
    subtitle: {
        display: "block",
        color: theme.palette.text.hint,
        fontWeight: theme.typography.fontWeightRegular,
    },
    infoItem: {
        paddingTop: 0,
        paddingBottom: 0,
        color: theme.palette.text.hint,
    },
    invalidDateIcon: {
        "& svg": {
            marginRight: 6,
            marginBottom: -2,
            width: 16,
            height: 16,
        },
    },
});

export interface SearchEventResultsProps {
    events: Event[],
    identifierClass?: string,
}

type SearchEventResultsStyledProps = WithStyles<typeof SearchEventResultsStyles>;
type SearchEventResultsRouterProps = RouteComponentProps<any>;
export type SearchEventResultsCombinedProps = SearchEventResultsProps & SearchEventResultsStyledProps & SearchEventResultsRouterProps;


function SearchEventResults(props: SearchEventResultsCombinedProps) {

    const { events, identifierClass, classes } = props;

    const handleGoEvent = (event: Event) => {
        const route = routeFactory
            .get(RouteName.EventProgram)
            .setParameters({
                departmentSlug: event.departmentSlug,
                townSlug: event.townSlug,
                eventTypeSlug: event.eventTypeSlug,
            })
            .fullPath();
        props.history.push(route);
    }

    const renderResult = (idx: number, event: Event): React.ReactElement => {
        const subtitle = `${event.eventTypeName}, ${getDatesRangeLabel(event.startAt, event.endAt, false, "Do")}`;
        const subtitleIcon = event.areDatesValidated === true 
            ? undefined 
            : <span className={classes.invalidDateIcon}><ErrorIcon /></span>;
        return (
            <ListItem
                key={idx}
                button onClick={() => handleGoEvent(event)}
                title={`${event.townName} | ${subtitle}`}
                className={classnames(classes.item, identifierClass)}
            >
                <ListItemText>

                    <Typography
                        component="h6"
                        variant="body1"
                        noWrap={true}
                        className={classes.title}
                    >
                        {event.townName}
                    </Typography>

                    <Typography
                        component="span"
                        variant="subtitle2"
                        noWrap={true}
                        className={classes.subtitle}
                    >
                        {subtitleIcon}
                        {subtitle}
                    </Typography>

                </ListItemText>
            </ListItem>
        )
    }

    const renderInfoItem = (str: string): React.ReactElement => {
        const { classes } = props;
        return (
            <ListItem className={classes.infoItem}>
                <ListItemText>
                    <Typography
                        component="span"
                        variant="subtitle2"
                    >
                        {str}
                    </Typography>
                </ListItemText>
            </ListItem>
        );
    }

    const nbResultInfo = events.length > 1 ? "fêtes trouvées" : "fête trouvée";

    return (
        <Paper className={classes.container}>
            <List disablePadding={true}>
                {
                    events.length > 0
                        ?
                        <React.Fragment>
                            {renderInfoItem(`${events.length} ${nbResultInfo}`)}    
                            {events.map((event, idx) => renderResult(idx, event))}
                        </React.Fragment>
                        :
                        renderInfoItem("Aucune fête trouvée")
                }

            </List>
        </Paper>
    );
}

export default withRouter(
    withStyles(SearchEventResultsStyles, { withTheme: true })(SearchEventResults)
);