import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import Container from "../Container";

const ToolbarDesktopStyles = (theme: Theme) => createStyles({
    containerRoot: {
        display: "flex",
        flex: "1 1 auto",
        backgroundColor: theme.palette.primary.main,
        padding: 0,
    },
    containerContainer: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        minHeight: 60,
        maxHeight: 60,
        height: 60,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        alignItems: "center",
    }
});

export interface ToolbarDesktopProps {
    children: React.ReactElement | React.ReactElement[],
}

type ToolbarDesktopStyledProps = WithStyles<typeof ToolbarDesktopStyles>;
export type ToolbarDesktopCombinedProps = ToolbarDesktopProps & ToolbarDesktopStyledProps;

function ToolbarDesktop(props: ToolbarDesktopCombinedProps) {

    const { children, classes } = props;

    return (
        <Container
            classes={{
                root: classes.containerRoot,
                container: classes.containerContainer
            }}
        >
            <div className={classes.row}>
                {children}
            </div>
        </Container>
    );
}

export default withStyles(ToolbarDesktopStyles, { withTheme: true })(ToolbarDesktop);