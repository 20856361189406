import React from "react";
import { Guid } from "guid-typescript";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import { Typography, Paper } from "@material-ui/core";

import ContactForm from ".";
import { FormProps } from "./Types";

const Styles = (theme: Theme) => createStyles({
    container: {
        maxWidth: 750,
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    header: {
        marginTop: theme.spacing(3),
        "& p": {
            margin: theme.spacing(1, 0, 0.5, 0),
        }
    },
});

export interface Props {
    eventId?: Guid,
    initialValue?: FormProps,
    onAfterSend?: (() => void),
    children?: any,
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function ContactFormPaper(props: CombinedProps) {

    const { classes, children, eventId, initialValue, onAfterSend } = props;

    return (
        <div className={classes.container}>

            {children
                ? <Typography component="div" variant="body1" className={classes.header}>{children}</Typography>
                : null
            }

            <Paper className={classes.paper}>
                <ContactForm
                    eventId={eventId}
                    initialValue={initialValue}
                    onAfterSend={onAfterSend}
                />
            </Paper>
        </div>
    );
}

export default withStyles(Styles, { withTheme: true })(ContactFormPaper);