import React from "react";

import { Hidden } from "@material-ui/core";

export interface OnDesktopProps {
    children: React.ReactElement | React.ReactElement[],
}

function OnDesktop(props: OnDesktopProps) {

    return (
        <Hidden smDown={true}>
            {props.children}
        </Hidden>
    )
}

export default OnDesktop;
