import React from "react";
import { Guid } from "guid-typescript";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { apiRequestFactory, ApiRequestName } from "../../tools/api";

const TimelineDayItemStyles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        margin: 8,
        marginTop: 0,
        minWidth: 200,
        width: 250,
        maxWidth: 250,
    },
    link: {
        paddingLeft: 0,
        paddingRight: 8,
        paddingBottom: 2,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        textDecoration: "none",
        "&:hover": {
            borderBottomColor: theme.palette.text.hint,
        },
    },
    title: {
        display: "block",
        color: theme.palette.text.primary,
    },
    subtitle: {
        display: "block",
        color: theme.palette.text.hint,
        fontWeight: theme.typography.fontWeightRegular,
    },
    subtitleIcon: {
        "& svg": {
            color: fade(theme.palette.common.black, 0.25),
            marginRight: 6,
            marginBottom: -2,
            width: 16,
            height: 16,
        },
    },
    cover: {
        height: 48,
        width: "100%",
        marginBottom: 2,
        overflow: "hidden",
        backgroundAttachment: "scroll",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
});

interface TimelineDayItemProps {
    label: string,
    title: string,
    subtitle: string,
    href: string,
    subtitleIcon?: React.ReactElement<SvgIconProps>,
    coverId?: Guid,
}

type TimelineDayItemStyledProps = WithStyles<typeof TimelineDayItemStyles>;
export type TimelineDayItemCombinedProps = TimelineDayItemProps & TimelineDayItemStyledProps;

function TimelineDayItem(props: TimelineDayItemCombinedProps) {
    const { label, title, subtitle, subtitleIcon,
        href, coverId, classes } = props;

    let coverPath = "";
    if (coverId) {
        coverPath = apiRequestFactory
            .get(ApiRequestName.ImageGet)
            .setParameters({ id: coverId.toString() })
            .fullPath();
    }

    return (
        <div className={classes.container}>

            <Link className={classes.link} to={href} title={label}>

                <Typography
                    component="h6"
                    variant="body1"
                    noWrap={true}
                    className={classes.title}
                >
                    {title}
                </Typography>

                <Typography
                    component="span"
                    variant="subtitle2"
                    noWrap={true}
                    className={classes.subtitle}
                >
                    {
                        subtitleIcon
                            ? <span className={classes.subtitleIcon}>{subtitleIcon}</span>
                            : null
                    }
                    {subtitle}
                </Typography>

                {
                    coverId
                        ? <div className={classes.cover} style={{ backgroundImage: `url(${coverPath})` }}></div>
                        : null
                }
            </Link>

        </div>
    )
}

export default withStyles(TimelineDayItemStyles, { withTheme: true })(TimelineDayItem);
