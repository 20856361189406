import Serializer from "../Serializer";
import StorageName, { toString } from "./StorageName";

function sessionStorageCache<T>(
    storageName: StorageName | string,
    callback: (() => T)): T {

    const key = typeof storageName === "string" ? storageName : toString(storageName);
    const serializer = new Serializer();

    const storeValue = sessionStorage.getItem(key);
    if (storeValue !== null) {
        return serializer.deserialize(storeValue) as T;
    }

    const requestedValue = callback() as T;
    sessionStorage.setItem(key, serializer.serialize(requestedValue));
    return requestedValue;
}

async function sessionStorageCacheAsync<T>(
    storageName: StorageName | string,
    callback: (() => Promise<T>)): Promise<T> {

    const key = typeof storageName === "string" ? storageName : toString(storageName);
    const serializer = new Serializer();

    const storeValue = sessionStorage.getItem(key);
    if (storeValue !== null) {
        return serializer.deserialize(storeValue) as T;
    }

    const requestedValue = await callback() as unknown as Promise<T>;
    sessionStorage.setItem(key, serializer.serialize(requestedValue));
    return requestedValue;
}

export {
    sessionStorageCache,
    sessionStorageCacheAsync,
}