import React from "react";

import TimelineDayItem from "./TimelineDayItem";
import { ErrorIcon } from "../../App";
import { Event } from "../../model/response";
import moment, { dateHashFormat, getDatesRangeLabel, numberOfDaysBetween } from "../../tools/moment";
import { routeFactory, RouteName } from "../../tools/route";

interface TimelineDayEventProps {
    day: Date,
    event: Event,
}

function TimelineDayEvent(props: TimelineDayEventProps) {
    const { day, event } = props;

    const mDay = moment(day);
    const mStartAt = moment(event.startAt);
    const dayIdx = numberOfDaysBetween(mDay, mStartAt) + 1;

    const hash = event.areDatesValidated ? mDay.format(dateHashFormat): "";
    const href = routeFactory
        .get(RouteName.EventProgram)
        .setParameters({
            departmentSlug: event.departmentSlug,
            townSlug: event.townSlug,
            eventTypeSlug: event.eventTypeSlug,
        })
        .setHash(hash)
        .fullPath();

    const title = `${event.townName}`;
    const subtitle = `${event.eventTypeName}, ${getDatesRangeLabel(event.startAt, event.endAt, false, "Do")}`;
    const subtitleIcon = event.areDatesValidated === true ? undefined : <ErrorIcon />;

    return (
        <TimelineDayItem
            label={`${title} | ${subtitle}`}
            title={title}
            subtitle={subtitle}
            subtitleIcon={subtitleIcon}
            href={href}
            coverId={dayIdx === 1 ? event.coverId : undefined}
        />
    )
}

export default TimelineDayEvent;
