import React from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";

import EventProgramPage from "./EventProgramPage";
import moment, { dateHashFormat, getDateHashParts } from "../../tools/moment"
import { apiRequestFactory, ApiRequestName } from "../../tools/api";
import { routeFactory, RouteName } from "../../tools/route";

import { Event } from "../../model/response";
import {
    sessionStorageCacheAsync,
    StorageName, toString,
} from "../../tools/storage";

interface RouteProps {
    departmentSlug: string,
    townSlug: string,
    eventTypeSlug: string,
}

export interface Props {
}

export interface State {
    loading: boolean,
    error: boolean,
    eventProgram: Event | undefined,
}

type RouterProps = RouteComponentProps<RouteProps>
export type CombinedProps = Props & RouterProps

class TimelineEventsPageContainer extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            eventProgram: undefined,
        }
    }

    componentDidMount() {
        this.loadEventProgram();
    }

    loadEventProgram = async () => {
        this.setState({ error: false, loading: true, eventProgram: undefined });

        try {
            const { departmentSlug, townSlug, eventTypeSlug } = this.props.match.params;
            const storageKey = `${toString(StorageName.EventProgram)}-${departmentSlug}-${townSlug}-${eventTypeSlug}`;

            const eventProgram = await sessionStorageCacheAsync<Event | undefined>(
                storageKey,
                () => apiRequestFactory
                    .get(ApiRequestName.EventProgram)
                    .setParameters({
                        departmentSlug,
                        townSlug,
                        eventTypeSlug,
                    })
                    .requestData<Event | undefined>()
            );

            this.setState({ error: false, loading: false, eventProgram });
        }
        catch (e) {
            this.setState({ error: true, loading: false, eventProgram: undefined });
        }

    }

    render() {
        const { hash } = this.props.location;

        const { error, loading, eventProgram } = this.state;

        let eventDay = undefined;
        if (eventProgram) {
            const { day: hashDay } = getDateHashParts(hash);
            eventDay = eventProgram.days.find(d => moment(d.date).format(`#${dateHashFormat}`) === hashDay);
            // todo : prendre en compte la partie du hash qui donne 
            // l'heure pour scroller directement dessus à l'ouveture
            if (!eventDay) {
                eventDay = eventProgram.days[0];
            }
        }

        if (error) {
            const route = routeFactory
                .get(RouteName.TimelineEvents)
                .fullPath();
            return <Redirect to={route} />;
        }

        return (
            <EventProgramPage
                loading={loading}
                eventProgram={eventProgram}
                eventDay={eventDay}
            />
        );
    }
}

export default withRouter(TimelineEventsPageContainer);