import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles, CSSProperties } from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

import moment from "../../tools/moment";
import { Month } from "../../model/response";
import { apiRequestFactory, ApiRequestName } from "../../tools/api";

const TimelineMonthRowStyles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        backgroundColor: theme.palette.primary.light,
        backgroundAttachment: "scroll",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    name: {
        fontFamily: "Righteous",
        textShadow: theme.palette.grey[800] + " 1px 1px 3px",
        width: "100%",
        textAlign: "center",
        textTransform: "uppercase",
        color: theme.palette.common.white,
        padding: theme.spacing(4, 0, 4, 0),
        // [theme.breakpoints.down("sm")]: {
            // padding: theme.spacing(2, 0, 2, 0),
        // },
    },
});

interface TimelineMonthRowProps {
    date: Date,
    months: Month[],
}

type TimelineMonthRowStyledProps = WithStyles<typeof TimelineMonthRowStyles>;
export type TimelineMonthRowCombinedProps = TimelineMonthRowProps & TimelineMonthRowStyledProps;

function TimelineMonthRow(props: TimelineMonthRowCombinedProps) {
    const { date, months, classes } = props;

    const mDate = moment(date);

    const monthName = mDate.format("MMMM");
    const id = mDate.format("YYYY-MM-01");

    const monthIdx = mDate.get("month") + 1;
    const month = months.find(x => x.month === monthIdx);
    const coverId = month ? month.coverId : undefined;
    let monthStyle = {} as CSSProperties;
    if (coverId) {
        const monthImage = apiRequestFactory
            .get(ApiRequestName.ImageGet)
            .setParameters({ id: coverId.toString() })
            .fullPath();
        monthStyle.backgroundImage = `url(${monthImage})`;
    }

    return <div
        id={id}
        className={classnames("timeline-month", classes.container)}
        style={monthStyle}
    >
        <Typography variant="h2" className={classes.name}>
            {monthName}
        </Typography>
    </div>
}

export default withStyles(TimelineMonthRowStyles, { withTheme: true })(TimelineMonthRow);