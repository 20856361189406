import React from "react";

import { Snackbar as MuiSnackbar, IconButton } from "@material-ui/core";
import { ClearIcon } from "../../App";

export interface Props {
    open: boolean,
    message: string,
    onClose: (() => void),
    autoHideDuration?: number,
}

function Snackbar(props: Props) {

    const { open, onClose, message } = props;

    const autoHideDuration = props.autoHideDuration ? props.autoHideDuration : 3000;

    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            onClose={onClose}
            message={message}
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                >
                    <ClearIcon />
                </IconButton>,
            ]}
        />
    );

}

export default Snackbar;