import React from "react";
import { Guid } from "guid-typescript";

import TimelineActivitiesPage from "./TimelineActivitiesPage";
import { apiRequestFactory, ApiRequestName } from "../../tools/api";
import moment, { numberOfDaysBetween } from "../../tools/moment";
import Serializer from "../../tools/Serializer";

import { DayActivities, FilterableActivityType, Month } from "../../model/response";
import {
    sessionStorageCacheAsync,
    sessionStorageCache,
    StorageName, toString,
} from "../../tools/storage";

export interface Props {
    onOpenDrawer: (() => void),
}

export interface State {
    timelineDays: Date[],
    timelineMonths: Month[],
    timelineLoading: boolean,
    timelineActivities: DayActivities[],
    selectedActivityTypesId: Guid[],
    filterableActivityTypes: FilterableActivityType[],
}

class TimelineActivitiesPageContainer extends React.Component<Props, State> {

    _serializer: Serializer;
    _requestFetched: boolean;

    constructor(props: Props) {
        super(props);

        this._serializer = new Serializer();
        this._requestFetched = false;

        this.state = {
            timelineDays: [],
            timelineMonths: [],
            timelineLoading: true,
            timelineActivities: [],
            selectedActivityTypesId: [],
            filterableActivityTypes: [],
        }
    }

    async componentDidMount() {
        window.addEventListener("scroll", this.onScroll);

        await this.loadTimelineDays();
        await this.loadTimelineMonths();
        await this.loadFilterableActivityTypes();
        this.updateTimelineActivities();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        const delta = 150;
        const scrollY = window.scrollY;
        const scrollMax = document.body.clientHeight - window.innerHeight;
        if (scrollY + delta >= scrollMax) {
            this.updateTimelineActivities();
        }
    }

    loadTimelineDays = async () => {
        try {
            let timelineDays = await sessionStorageCacheAsync<Date[]>(
                StorageName.TimelineDays,
                () => apiRequestFactory
                    .get(ApiRequestName.ListTimelineDays)
                    .requestData<Date[]>()
            );
            // timelineDays = timelineDays.map(x => new Date(x));
            this.setState({ timelineDays });
        }
        catch (e) {
            this.setState({ timelineDays: [] });
        }
    }

    loadTimelineMonths = async () => {
        try {
            const timelineMonths = await sessionStorageCacheAsync<Month[]>(
                StorageName.TimelineMonths,
                () => apiRequestFactory
                    .get(ApiRequestName.ListTimelineMonths)
                    .requestData<Month[]>()
            );
            this.setState({ timelineMonths });
        }
        catch (e) {
            this.setState({ timelineMonths: [] });
        }
    }

    loadFilterableActivityTypes = async () => {
        try {

            const filterableActivityTypes = await sessionStorageCacheAsync<FilterableActivityType[]>(
                StorageName.FilterableActivityTypes,
                () => apiRequestFactory
                    .get(ApiRequestName.ListFilterableActivityTypes)
                    .requestData<FilterableActivityType[]>()
            );

            const selectedActivityTypesId = sessionStorageCache<Guid[]>(
                StorageName.SelectedActivityTypesId,
                () => {
                    let selectedId = [] as Guid[];
                    filterableActivityTypes.forEach(at => {
                        at.isSelected = at.isDefaultSelected;
                        if (at.isSelected) {
                            selectedId.push(at.id);
                        }
                    });
                    return selectedId;
                }
            );

            this.setState({ filterableActivityTypes, selectedActivityTypesId });
        }
        catch (e) {
            this.setState({ filterableActivityTypes: [], selectedActivityTypesId: [] });
        }
    }

    updateTimelineActivities = async (top: number = 0) => {
        try {
            let { timelineActivities, selectedActivityTypesId, timelineLoading } = this.state;

            if (this._requestFetched || !timelineLoading) {
                return;
            }

            this._requestFetched = true;

            top += 12;
            const nextDate = this.getTimelineEventsNextDate();
            const activityTypeIds = this._serializer.serialize(selectedActivityTypesId);
            const query = `startAt=${nextDate.toISOString()}&top=${top}&activityTypeIds=${activityTypeIds}`;

            const newDayActivities = await apiRequestFactory
                .get(ApiRequestName.TimelineActivities)
                .setQuery(query)
                .requestData() as DayActivities[];

            timelineActivities.push(...newDayActivities);
            timelineLoading = newDayActivities.length === top;

            this.setState({ timelineActivities, timelineLoading });

            this._requestFetched = false;
        }
        catch (e) {
            console.error(e);
        }
    }

    getTimelineEventsNextDate = (): Date => {
        const { timelineActivities } = this.state;
        let nextDate = moment();
        const nbActivities = timelineActivities.length;
        if (nbActivities > 0) {
            nextDate = moment(timelineActivities[nbActivities - 1].date)
                .add(1, "days");
        }
        nextDate = nextDate.startOf("day"); // remove time
        return nextDate.toDate();
    }

    handleUpdateTimelineActivitiesTo = async (toDate: Date) => {
        const { timelineDays } = this.state;
        const nextDate = this.getTimelineEventsNextDate();

        const mNext = moment(nextDate);
        const mTo = moment(toDate);

        let top = numberOfDaysBetween(mNext, mTo);
        if (timelineDays.length > 0) {
            top = timelineDays.filter(x => moment(x).isBetween(mNext, mTo)).length + 1;
        }

        await this.updateTimelineActivities(top);
    }

    handleFilterActivityTypes = (ids: Guid[]) => {
        if (ids.length === 0) {
            return;
        }

        sessionStorage.setItem(toString(StorageName.SelectedActivityTypesId),
            this._serializer.serialize(ids));

        this.setState({
            timelineActivities: [],
            timelineLoading: true,
            selectedActivityTypesId: ids,
        }, this.updateTimelineActivities);
    }

    render() {
        const { onOpenDrawer } = this.props;
        const {
            timelineDays, timelineMonths,
            timelineLoading, timelineActivities,
            filterableActivityTypes, selectedActivityTypesId,
        } = this.state;

        return (
            <TimelineActivitiesPage
                onOpenDrawer={onOpenDrawer}

                timelineMonths={timelineMonths}

                timelineActivities={timelineActivities}
                timelineLoading={timelineLoading}
                onUpdateTimelineTo={this.handleUpdateTimelineActivitiesTo}

                datepickerDays={timelineDays}

                filterableActivityTypes={filterableActivityTypes}
                selectedActivityiTypesId={selectedActivityTypesId}
                onFilterActivityTypes={this.handleFilterActivityTypes}
            />
        );
    }
}

export default TimelineActivitiesPageContainer;