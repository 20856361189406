import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import FilterIcon from "@material-ui/icons/List";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowLeftIcon from "@material-ui/icons/ArrowBackIos";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorIcon from "@material-ui/icons/Error";
import OfficalIcon from "@material-ui/icons/AccountBalance";
import BusIcon from "@material-ui/icons/AirportShuttle";
import ClockIcon from "@material-ui/icons/AccessTime";
import ClockOffIcon from "@material-ui/icons/AlarmOff";
import { ReactComponent as FacebookIcon } from "./facebook.svg";
import EventsIcon from "@material-ui/icons/CalendarToday";
import ActivitiesIcon from "@material-ui/icons/Extension";
import ContactIcon from "@material-ui/icons/Info";
import SendIcon from "@material-ui/icons/Send";
import ShareIcon from "@material-ui/icons/Share";
import DevicesIcon from "@material-ui/icons/Devices";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#be2626",
      dark: "#a22020",
    },
    secondary: blue,
  },
});

theme = responsiveFontSizes(theme);

export default theme;

export {
  MenuIcon, SearchIcon, FilterIcon,
  ArrowUpIcon, ArrowDownIcon, ArrowLeftIcon,
  CalendarIcon, ClearIcon, ErrorIcon,
  OfficalIcon, BusIcon,
  ClockIcon, ClockOffIcon,
  FacebookIcon, ShareIcon,
  EventsIcon, ActivitiesIcon,
  ContactIcon, SendIcon,
  DevicesIcon,
}