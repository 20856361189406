import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography, Collapse, Button } from "@material-ui/core";

import TimelineRow from "./TimelineRow";
import moment from "../../tools/moment";

const Styles = (theme: Theme) => createStyles({
    dayStr: {
        textTransform: "uppercase",
        color: theme.palette.text.hint,
        textAlign: "center",
    },
    dayIdx: {
        marginTop: -6,
        textAlign: "center",
    },
    rowRightContainer: {
        flexDirection: "column",
    },
    itemsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    moreBtn: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 250,
        maxWidth: 250,
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(1),
            marginRight: 0,
        },
        padding: theme.spacing(0.5, 2),
        textTransform: "none",
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
        borderStyle: "solid",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[100],
    },
});

interface Props {
    day: Date,
    children: React.ReactElement[],
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

interface State {
    collapsed: boolean,
}

class TimelineDayRow extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            collapsed: false,
        }
    }

    toggleCollapsed = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    render() {
        const { day, classes, children } = this.props;
        const { collapsed } = this.state;

        const mDay = moment(day);
        const dayStr = mDay.format("ddd").replace(".", "");
        const dayIdx = mDay.format("DD");
        const dayId = mDay.format("YYYY-MM-DD");

        const dayComponent = <React.Fragment>
            <Typography variant="body2" className={classes.dayStr}>{dayStr}</Typography>
            <Typography variant="h5" className={classes.dayIdx}>{dayIdx}</Typography>
        </React.Fragment>

        const maxChildren = 6;
        let presentChildren = [] as React.ReactElement[];
        let additionalChildren = [] as React.ReactElement[];
        for (let i = 0; i < children.length && i < maxChildren; i++) {
            presentChildren.push(children[i]);
        }
        for (let i = maxChildren; i < children.length; i++) {
            additionalChildren.push(children[i]);
        }

        return (
            <TimelineRow
                id={dayId}
                className="timeline-day"
                chip={true}
                leftComponent={dayComponent}
                classes={{
                    rightContainer: classes.rowRightContainer,
                }}
            >

                <div className={classes.itemsContainer}>
                    {presentChildren}
                </div>

                {additionalChildren.length > 0
                    ?
                    <React.Fragment>
                        <Collapse in={collapsed} timeout="auto" unmountOnExit>
                            <div className={classes.itemsContainer}>
                                {additionalChildren}
                            </div>
                        </Collapse>
                        <Button
                            onClick={this.toggleCollapsed}
                            className={classes.moreBtn}
                        >
                            {collapsed
                                ? <React.Fragment>
                                    Réduire
                                </React.Fragment>
                                : <React.Fragment>
                                    Tout afficher (+{additionalChildren.length})
                                </React.Fragment>
                            }
                        </Button>
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                }

            </TimelineRow>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(TimelineDayRow);