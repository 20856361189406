import React from "react";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

import { ClockIcon } from "../../App";
import TimelineRow from "./TimelineRow";

const TimelineTimeRowStyles = (theme: Theme) => createStyles({
    rightContainer: {
        flexDirection: "column",
    },
    timeHour: {
        fontVariantNumeric: "tabular-nums",
        textAlign: "right",
        marginTop: -2,
        marginRight: 5,
    },
    timeMinute: {
        fontSize: 16,
        color: fade(theme.palette.text.primary, 0.40),
        fontVariantNumeric: "tabular-nums",
        letterSpacing: 1,
        textAlign: "right",
        marginTop: -10,
        marginRight: 5,
    },
});

interface TimelineTimeRowProps {
    time: string,
    children: React.ReactElement | React.ReactElement[],
}

type TimelineTimeRowStyledProps = WithStyles<typeof TimelineTimeRowStyles>;
export type TimelineTimeRowCombinedProps = TimelineTimeRowProps & TimelineTimeRowStyledProps;

function TimelineTimeRow(props: TimelineTimeRowCombinedProps) {
    const { time, classes, children } = props;

    const parts = time.split(":");
    const timeHour = parts[0];
    const timeMinute = parts[1];

    const timeComponent = <React.Fragment>
        <Typography variant="h5" className={classes.timeHour}>{timeHour}</Typography>
        <Typography variant="h6" className={classes.timeMinute}>{timeMinute}</Typography>
    </React.Fragment>

    return (
        <TimelineRow
            id={time}
            className="timeline-time"
            chip={<ClockIcon />}
            leftComponent={timeComponent}
            classes={{
                rightContainer: classes.rightContainer,
            }}
        >
            {children}
        </TimelineRow>
    )
}

export default withStyles(TimelineTimeRowStyles, { withTheme: true })(TimelineTimeRow);