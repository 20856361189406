import React from "react";
import ReactGA from "react-ga";
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import Drawer from "../components/Drawer";
import { routeFactory, RouteName } from "../tools/route";
import { history } from "../tools/History";

import TimelineEventsPage from "../pages/TimelineEvents";
import TimelineActivitiesPage from "../pages/TimelineActivities";
import EventProgramPage from "../pages/EventProgram";
import ContactPage from "../pages/Contact";
import CguPage from "../pages/Cgu";
import { DepreciatedEventProgramPage } from "../pages/Depreciated";

import { OnMobile } from "../components/Hidden";

const Styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    margin: 0,
    padding: 0,
  },
});

ReactGA.initialize("UA-12605971-1");

export interface Props {
}

type RouterProps = RouteComponentProps<any>
type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps & RouterProps;

export interface State {
  drawerIsOpen: boolean,
}

class App extends React.Component<CombinedProps, State> {

  eventsRoute: string;
  activitiesRoute: string;
  programRoute: string;
  contactRoute: string;
  cguRoute: string;
  depreciatedProgramRoute: string;

  constructor(props: CombinedProps) {
    super(props);

    this.eventsRoute = routeFactory.fullPath(RouteName.TimelineEvents);
    this.activitiesRoute = routeFactory.fullPath(RouteName.TimelineActivities);
    this.programRoute = routeFactory.fullPath(RouteName.EventProgram);
    this.contactRoute = routeFactory.fullPath(RouteName.Contact);
    this.cguRoute = routeFactory.fullPath(RouteName.Cgu);
    this.depreciatedProgramRoute = routeFactory.fullPath(RouteName.DepreciatedEventProgram);

    this.state = {
      drawerIsOpen: false,
    }
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
    history.pushPage(this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps:CombinedProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      ReactGA.pageview(nextProps.location.pathname);
      history.pushPage(nextProps.location.pathname);
    }
  }

  openDrawer = () => {
    this.setState({ drawerIsOpen: true });
  }
  closeDrawer = () => {
    this.setState({ drawerIsOpen: false });
  }


  render() {
    const { classes } = this.props;
    const { drawerIsOpen } = this.state;

    return (
      <div className={classes.root}>

        <OnMobile>
          <Drawer
            open={drawerIsOpen}
            onClose={this.closeDrawer}
          />
        </OnMobile>

        <Switch>

          <Route exact path={this.eventsRoute}
            render={() =>
              <TimelineEventsPage
                onOpenDrawer={this.openDrawer}
              />
            }
          />

          <Route exact path={this.activitiesRoute}
            render={() =>
              <TimelineActivitiesPage
                onOpenDrawer={this.openDrawer}
              />
            }
          />

          <Route exact path={this.programRoute} component={EventProgramPage} />

          <Route exact path={this.contactRoute}
            render={() =>
              <ContactPage
                onOpenDrawer={this.openDrawer}
              />
            }
          />

          <Route exact path={this.cguRoute}
            render={() =>
              <CguPage
                onOpenDrawer={this.openDrawer}
              />
            }
          />

          {/* depreciated event program route */}
          <Route exact path={this.depreciatedProgramRoute} component={DepreciatedEventProgramPage} />

          {/* route not found > redirect to home page */}
          <Route path="/" render={() => <Redirect to={this.eventsRoute} />} />

        </Switch>

      </div>
    );
  }
}

export default withRouter(
  withStyles(Styles, { withTheme: true })(App)
);
