enum StorageName {
    TimelineDays,
    TimelineMonths,
    FilterableActivityTypes,
    SelectedActivityTypesId,
    EventProgram,
}

export default StorageName;

export function toString(name: StorageName): string {
    return StorageName[name];
}
