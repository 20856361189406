import React from "react";
import { Link } from "react-router-dom";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { Typography } from "@material-ui/core";

import Spacer from "../Spacer";
import { ToolbarDesktop } from ".";
import { routeFactory, RouteName } from "../../tools/route";

const MainToolbarDesktopStyles = (theme: Theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    flex: "1 1 auto",
  },
  title: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  titleStrong: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  menu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menuLink: {
    color: fade(theme.palette.common.white, 0.40),
    padding: theme.spacing(2, 4),
    transition: theme.transitions.create("color"),
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
})

type MainToolbarDesktopStyledProps = WithStyles<typeof MainToolbarDesktopStyles>;
export type MainToolbarDesktopCombinedProps = MainToolbarDesktopStyledProps;

function MainToolbarDesktop(props: MainToolbarDesktopCombinedProps) {

  const { classes } = props;

  const eventsRoute = routeFactory.fullPath(RouteName.TimelineEvents);
  const activitiesRoute = routeFactory.fullPath(RouteName.TimelineActivities);
  const contactRoute = routeFactory.fullPath(RouteName.Contact);

  return (
    <ToolbarDesktop>

      <div className={classes.container}>

        <Link to={eventsRoute}>
          <Typography variant="h6" className={classes.title}>
            Ferias<span className={classes.titleStrong}>40</span>
          </Typography>
        </Link>

        <Spacer />

        <div className={classes.menu}>
          <Link className={classes.menuLink} to={eventsRoute}>
            <Typography variant="h6">Fêtes</Typography>
          </Link>
          <Link className={classes.menuLink} to={activitiesRoute}>
            <Typography variant="h6">Activités</Typography>
          </Link>
          <Link className={classes.menuLink} to={contactRoute}>
            <Typography variant="h6">Contact</Typography>
          </Link>
        </div>

      </div>

    </ToolbarDesktop>
  );
}

export default withStyles(MainToolbarDesktopStyles, { withTheme: true })(MainToolbarDesktop);
