import React from "react";

import { Hidden } from "@material-ui/core";

export interface OnMobileProps {
    children: React.ReactElement | React.ReactElement[],
}

function OnMobile(props: OnMobileProps) {

    return (
        <Hidden mdUp={true}>
            {props.children}
        </Hidden>
    )
}

export default OnMobile;
