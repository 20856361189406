import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { AppBar } from "@material-ui/core";

import MenubarDesktop from "./Menubar.desktop";
import ToolbarMobile from "./Toolbar.mobile";
import MenubarMobile from "./Menubar.mobile";
import Cgu from "./Cgu";
import { OnDesktop, OnMobile } from "../../components/Hidden";
import { MainToolbarDesktop, ToolbarSpacer } from "../../components/Toolbar";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import { MenubarSpacerMobile } from "../../components/Menubar";

const Styles = (theme: Theme) => createStyles({
    container: {
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        }
    },
    title: {
        margin: theme.spacing(3, 0, 1, 0),
    },
    block: {
        margin: theme.spacing(1, 0),
    },
});

export interface Props {
    onOpenDrawer: (() => void),
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

class CguPage extends React.Component<CombinedProps> {

    componentDidMount() {
        document.title = "CGU et mentions légales - Ferias40";
        
        window.scrollTo({ top: 0 });
    }

    render() {
        const { onOpenDrawer, classes } = this.props;

        return (
            <React.Fragment>

                <AppBar position="fixed">

                    <OnDesktop>
                        <MainToolbarDesktop />
                        <MenubarDesktop />
                    </OnDesktop>

                    <OnMobile>
                        <ToolbarMobile
                            onOpenDrawer={onOpenDrawer}
                        />
                        <MenubarMobile />
                    </OnMobile>

                </AppBar>

                <Container>

                    <ToolbarSpacer />
                    <MenubarSpacerMobile />

                    <div className={classes.container}>

                        <Cgu />

                    </div>

                    <OnDesktop>
                        <Footer />
                    </OnDesktop>

                </Container>

            </React.Fragment>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(CguPage);