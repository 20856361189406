import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CircularProgress } from "@material-ui/core";

const TimelineDayLoaderStyles = (theme: Theme) => createStyles({
    loading: {
        display: "block",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
    }
});


type TimelineDayLoaderStyledProps = WithStyles<typeof TimelineDayLoaderStyles>;

function TimelineDayLoader(props: TimelineDayLoaderStyledProps) {
    const { classes } = props;

    return (
        <div className={classes.loading}><CircularProgress /> </div>
    )
}

export default withStyles(TimelineDayLoaderStyles, { withTheme: true })(TimelineDayLoader);