import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import TimelineRow from "./TimelineRow";
import TimelineTimeItem from "./TimelineTimeItem";
import { BusIcon } from "../../App";

const TimelineTimeBusRowStyles = (theme: Theme) => createStyles({
    rowRoot: {
        backgroundColor: "#edcbcb",
    },
    rowSeparator: {
        borderRightStyle: "dotted",
    },
    busIcon: {
        width: 16,
        height: 16,
        marginLeft: 8,
        color: theme.palette.primary.main,
        backgroundColor: "inherit",
    },
    itemContainer: {
        borderBottomWidth: 0,
        paddingBottom: 0,
    }
});

interface TimelineTimeBusRowProps {
    busUrl: string,
}

type TimelineTimeBusRowStyledProps = WithStyles<typeof TimelineTimeBusRowStyles>;
export type TimelineTimeBusRowCombinedProps = TimelineTimeBusRowProps & TimelineTimeBusRowStyledProps;

function TimelineTimeBusRow(props: TimelineTimeBusRowCombinedProps) {
    const { busUrl, classes } = props;

    const iconComponent = <span className={classes.busIcon}><BusIcon /></span>
    const title = "Les bus des ferias";
    let description = "Pour votre sécurité, pensez à prendre le bus des ferias.\n\n"
    description += `[Plus d’informations et horaires](${busUrl})`;

    return (
        <TimelineRow
            chip={false}
            leftComponent={iconComponent}
            classes={{
                root: classes.rowRoot,
                separator: classes.rowSeparator,
            }}
        >

            <TimelineTimeItem
                title={title}
                description={description}
                classes={{ container: classes.itemContainer }}
            />

        </TimelineRow>
    )
}

export default withStyles(TimelineTimeBusRowStyles, { withTheme: true })(TimelineTimeBusRow);