import React from "react";
import { Guid } from "guid-typescript";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"

import ContactForm from ".";
import { ContactIcon } from "../../App";
import Dialog from "../Dialog";
import { FormProps } from "./Types";
import { Button } from "@material-ui/core";

const Styles = (theme: Theme) => createStyles({
});

export interface Props {
    eventId?: Guid,
    initialValue?: FormProps,
    onAfterSend?: (() => void),
    open: boolean,
    onClose: (() => void),
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function ContactFormDialogDesktop(props:CombinedProps) {

    const { initialValue, eventId, open, onClose, onAfterSend } = props;

    return (
        <Dialog
            id="event-contact-info-dialog"
            open={open}
            onClose={onClose}
            title="Besoin d'un renseignement ?"
            titleIcon={<ContactIcon />}
            hideActions={true}
        >
        
            <ContactForm 
                eventId={eventId}
                initialValue={initialValue}
                onAfterSend={onAfterSend}
                extraAction={
                    <Button onClick={onClose} color="primary" variant="contained">
                        Fermer
                    </Button>
                }
            />

        </Dialog>
    );
}

export default withStyles(Styles, { withTheme: true })(ContactFormDialogDesktop);