import React from "react";

import TimelineTimeItem from "./TimelineTimeItem";
import { Activity } from "../../model/response";

interface TimelineTimeActivityProps {
    activity: Activity,
}

function TimelineTimeActivity(props: TimelineTimeActivityProps) {
    const { activity } = props;

    return (
        <TimelineTimeItem
            title={activity.name}
            description={activity.description}
            coverId={activity.coverId}
        />
    )
}

export default TimelineTimeActivity;
