import React from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { routeFactory, RouteName } from "../../tools/route";

interface RouteProps {
    year: string,
    townSlug: string,
    eventTypeSlug: string,
}

type RouterProps = RouteComponentProps<RouteProps>
export type CombinedProps = RouterProps

class DepreciatedEventProgramPage extends React.Component<CombinedProps> {

    render() {

        let { townSlug, eventTypeSlug } = this.props.match.params;

        townSlug = townSlug.toLowerCase();
        eventTypeSlug = eventTypeSlug.toLowerCase();

        const redirectRoute = routeFactory
            .get(RouteName.EventProgram)
            .setParameters({
                departmentSlug: "landes",
                townSlug,
                eventTypeSlug,
            })
            .fullPath();

        return <Redirect to={redirectRoute} />
    }

}

export default withRouter(DepreciatedEventProgramPage);