import RouteName from "./RouteName";
import RouteConfiguration from "./RouteConfiguration";

const routeConfiguration = new RouteConfiguration()
    .add(RouteName.TimelineEvents, "/")
    .add(RouteName.TimelineActivities, "/activites")
    .add(RouteName.EventProgram, "/:departmentSlug/:townSlug/:eventTypeSlug/programme")
    .add(RouteName.Contact, "/contact")
    .add(RouteName.Cgu, "/cgu")
    .add(RouteName.Facebook, "https://www.facebook.com/Ferias40.fr")

    .add(RouteName.DepreciatedEventProgram, "/:year/Fetes/:townSlug/:eventTypeSlug")

    ;

export default routeConfiguration;