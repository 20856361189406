import React from "react";

import MomentUtils from "@date-io/moment";
import { Moment } from "moment";

import {
    MaterialUiPickersDate,
    MuiPickersUtilsProvider,
    DatePicker as MuiDatePicker,
} from "@material-ui/pickers";

class LocalizedUtils extends MomentUtils {
    getDatePickerHeaderText(date: Moment): string {
        return date.format("ddd D MMM");
    }
}

export interface DatepickerProps {
    isOpen: boolean,
    onClose: (() => void),
    value: Date,
    onChange?: ((date: Date) => void),
    format: string,
    maxDate?: Date,
    timelineDays: Date[],
    className?: string,
    style?: React.CSSProperties,
}

function Datepicker(props: DatepickerProps) {

    const { 
        isOpen, onClose, 
        timelineDays, value, format, maxDate, 
        style, className,
    } = props;

    const todayTime = new Date().getTime();
    const timelineDaysTime = timelineDays.map(x => x.getTime());

    const handleChange = (date: MaterialUiPickersDate) => {
        if (props.onChange && date) {
            props.onChange(date.toDate());
        }
    }

    const isUnvalidDate = (date: MaterialUiPickersDate): boolean => {
        if (timelineDaysTime.length === 0 || !date) return false;

        const dateTime = date.toDate().getTime();
        if (todayTime === dateTime) return false; // today is forced valid

        return timelineDaysTime.find(x => x === dateTime) === undefined;
    }

    return (
        <MuiPickersUtilsProvider utils={LocalizedUtils}>
            <MuiDatePicker
                open={isOpen}
                value={value}
                onClose={onClose}
                onChange={handleChange}
                disablePast={true}
                maxDate={maxDate}
                format={format}
                inputVariant="standard"
                cancelLabel="annuler"
                showTodayButton={true}
                todayLabel="Aujourd'hui"
                clearable={false}
                fullWidth={false}
                className={className}
                shouldDisableDate={isUnvalidDate}
                style={style}
            />
        </MuiPickersUtilsProvider>
    );
}

export default Datepicker;