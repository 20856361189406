import ContactForm from "./ContactForm.container";
import ContactFormDialogDesktop from "./ContactFormDialog.desktop";
import ContactFormDialogMobile from "./ContactFormDialog.mobile";
import ContactFormPaper from "./ContactFormPaper";

export default ContactForm;
export * from "./Types";

export {
    ContactFormDialogDesktop,
    ContactFormDialogMobile,
    ContactFormPaper,
};