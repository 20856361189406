import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const ContainerStyles = (theme: Theme) => createStyles({
  root: {
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 876,
  },
});

export interface ContainerProps {
  children: React.ReactElement | React.ReactElement[],
}

type ContainerStyledProps = WithStyles<typeof ContainerStyles>;
export type ContainerCombinedProps = ContainerProps & ContainerStyledProps;

function Container(props: ContainerCombinedProps) {

  const { classes, children } = props

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {children}
      </div>
    </div>
  );
}

export default withStyles(ContainerStyles, { withTheme: true })(Container);
