import React from "react";

import { MenubarDesktop as CMenubarDesktop } from "../../components/Menubar";

function MenubarDesktop() {
    return (
        <CMenubarDesktop
            title="Contact"
            subtitle="Besoins de renseignements ? Contactez nous"
            collapseHidden={true}
        />
    );
}

export default MenubarDesktop;