import React from "react";

import { DayEvents, Month } from "../../model/response";
import { TimelineDayEvent } from "../../components/Timeline";
import { TimelineDayRow, TimelineMonthRow, TimelineRowSeparator } from "../../components/Timeline";
import { numberOfDaysBetween, numberOfMonthBetween } from "../../tools/moment";

export interface Props {
    timelineMonths: Month[],
    dayEvents: DayEvents[],
}

function TimelineEvents(props: Props) {

    const { dayEvents, timelineMonths } = props;

    return (
        <React.Fragment>

            {dayEvents.map((de, idx) => {

                const isPrevious = idx > 0
                    ? numberOfDaysBetween(de.date, dayEvents[idx - 1].date) < 2
                    : true;

                const isSameMonth = idx > 0
                    ? numberOfMonthBetween(de.date, dayEvents[idx - 1].date) === 0
                    : true;

                return (
                    <React.Fragment key={idx}>
                        {isPrevious && isSameMonth ? null : <TimelineRowSeparator />}
                        {isSameMonth ? null : <TimelineMonthRow date={de.date} months={timelineMonths} />}
                        <TimelineDayRow day={de.date}>
                            {de.events.map((e, edx) =>
                                <TimelineDayEvent day={de.date} event={e} key={edx} />
                            )}
                        </TimelineDayRow>
                    </React.Fragment>
                )
            })}

        </React.Fragment>
    )
}

export default TimelineEvents;