export enum Subject {
    ValidateEventDates,
    SetEventProgram,
    AskEventInformations,
    Other,
}

export class FormProps {
    email!: string;
    subject!: Subject;
    subjectComplement!: string;
    description!: string;

    public static Fields = {
        email: "email",
        subject: "subject",
        subjectComplement: "subjectComplement",
        description: "description",
    }
}

export const subjectList = [
    Subject.ValidateEventDates,
    Subject.SetEventProgram,
    Subject.AskEventInformations,
    Subject.Other,
] as Subject[];


export function getSubjectLabel(s: Subject): string {
    switch (s) {
        case Subject.ValidateEventDates: return "Validation des dates";
        case Subject.SetEventProgram: return "Partage d'un programme";
        case Subject.AskEventInformations: return "Demande de renseignements";
        case Subject.Other:
        default: return "Autre";
    }
}

export function getSubjectComplementLabel(s: Subject): string {
    switch (s) {
        case Subject.ValidateEventDates: return "Précisez la fête"
        case Subject.SetEventProgram: return "Précisez la fête";
        case Subject.AskEventInformations: return "Précisez la fête";
        case Subject.Other:
        default: return "Précisez l'objet";
    }
}

export function getSubjectComplementPlaceholder(s: Subject): string {
    switch (s) {
        case Subject.ValidateEventDates: return "ex: Fêtes patronales de Mugron"
        case Subject.SetEventProgram: return "ex : Fêtes patronales de Mugron";
        case Subject.AskEventInformations: return "ex: Fêtes patronales de Mugron";
        case Subject.Other:
        default: return "";
    }
}