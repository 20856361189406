import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

// --------------------------------------------------------

const TitleStyles = (theme: Theme) => createStyles({
    title: {
        margin: theme.spacing(3, 0, 1, 0),
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
});

interface TitleProps {
    children: any,
}

type TitleStyledProps = WithStyles<typeof TitleStyles>;
type TitleCombinedProps = TitleStyledProps & TitleProps;

function _Title(props:TitleCombinedProps) {
    const { classes, children } = props;
    return (
        <Typography component="h3" variant="h5" className={classes.title}>
            {children}
        </Typography>
    );
}

const Title = withStyles(TitleStyles, { withTheme: true })(_Title);

// --------------------------------------------------------

const ParagrahStyles = (theme: Theme) => createStyles({
    paragrah: {
        margin: theme.spacing(1, 0),
        "& a": {
            color: theme.palette.grey[700],
            textDecoration: "underline",
        }
    },
});

interface ParagrahProps {
    children: any,
}

type ParagrahStyledProps = WithStyles<typeof ParagrahStyles>;
type ParagrahCombinedProps = ParagrahStyledProps & ParagrahProps;

function _Paragrah(props:ParagrahCombinedProps) {
    const { classes, children } = props;
    return (
        <Typography component="div" variant="body2" className={classes.paragrah}>
            {children}
        </Typography>
    );
}

const Paragrah = withStyles(ParagrahStyles, { withTheme: true })(_Paragrah);

// --------------------------------------------------------

function Cgu() {

    const site = "ferias40.fr";

    return (
        <React.Fragment>

            <Title>Mentions légales</Title>

            <Paragrah>
                En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance 
                dans l'économie numérique, il est précisé aux utilisateurs du site 
                {site} l'identité des différents intervenants dans le cadre de sa réalisation 
                et de son suivi.
            </Paragrah>
            <Paragrah>
                Propriétaire, Créateur, Responsable publication, Webmaster : Luc Lafitte - 33400 Talence
            </Paragrah>
            <Paragrah>
                Contact : <a href="mailto:info@ferias40.fr">info@ferias40.fr</a>
            </Paragrah>
            <Paragrah>
                Le responsable de publication est une personne physique.
            </Paragrah>
            <Paragrah>
                Hébergeur : OVH SAS - RCS Lille Métropole 424 761 419 00045 - Code APE 2620Z - 
                N° TVA : FR 22 424 761 419 - Siège social : 2 rue Kellermann - 59100 Roubaix - France
            </Paragrah>


            <Title>Conditions générales d’utilisation et des services proposés</Title>

            <Paragrah>
                L’utilisation du site {site} implique l’acceptation pleine et entière des conditions 
                générales d’utilisation ci-après décrites. 
                Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout 
                moment, les utilisateurs du site {site} sont donc invités à les consulter de manière 
                régulière.
            </Paragrah>
            <Paragrah>
                Le site {site} est mis à jour régulièrement par l’Éditeur. De la même façon, les 
                mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à 
                l’Utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre 
                connaissance.
            </Paragrah>


            <Title>Objet</Title>

            <Paragrah>
                Les présentes « conditions générales d'utilisation » ont pour objet l'encadrement 
                juridique de l’utilisation du site {site} et de ses services.
            </Paragrah>
            <Paragrah>
                Ce contrat est conclu entre :
                <ul>
                    <li>
                        Le gérant du site internet, ci-après désigné « l’Éditeur »
                    </li>
                    <li>
                        Toute personne physique ou morale souhaitant accéder au site et à ses services, 
                        ci-après appelé « l’Utilisateur ».
                    </li>
                </ul>
            </Paragrah>
            <Paragrah>
                Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, 
                et son accès au site vaut acceptation de ces conditions.
            </Paragrah>


            <Title>Description et accès aux services</Title>

            <Paragrah>
                Tout utilisateur ayant accès à internet peut accéder gratuitement et depuis n’importe 
                où au site. Les frais supportés par l’Utilisateur pour y accéder (connexion internet, 
                matériel informatique, etc.) ne sont pas à la charge de l’Éditeur.
            </Paragrah>
            <Paragrah>
                Le site et ses différents services peuvent être interrompus ou suspendus par l’Éditeur, 
                notamment à l’occasion d’une maintenance, sans obligation de préavis ou de justification.
            </Paragrah>

            
            <Title>Responsabilité de l’Utilisateur</Title>

            <Paragrah>
                L’Utilisateur assume l’entière responsabilité de l’utilisation qu’il fait des 
                informations et contenus présents sur le site {site}.
            </Paragrah>
            <Paragrah>
                Tout usage du service par l'Utilisateur ayant directement ou indirectement pour 
                conséquence des dommages doit faire l'objet d'une indemnisation au profit du site.
            </Paragrah>


            <Title>Responsabilité de l’Éditeur</Title>

            <Paragrah>
                Le site {site} ne pourra être tenu responsable de dommages matériels liés à l’utilisation 
                du site. 
                De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel 
                récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
            </Paragrah>
            <Paragrah>
                L’Éditeur ne pourra être tenu responsable des dommages directs et indirects causés 
                au matériel de l’utilisateur, lors de l’accès au site {site}, et résultant soit de 
                l’utilisation d’un matériel ne répondant pas aux spécifications indiquées précédemment, 
                soit de l’apparition d’un bug ou d’une incompatibilité.
            </Paragrah>
            <Paragrah>
                L’Éditeur ne pourra également être tenu responsable des dommages indirects 
                (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à 
                l’utilisation du site {site}.
            </Paragrah>
            <Paragrah>
                L’Éditeur se réserve la faculté d’une non-garantie de la fiabilité des sources, 
                bien que les informations diffusées sur le site soient réputées fiables.
            </Paragrah>
            <Paragrah>
                Tout dysfonctionnement du serveur ou du réseau ne peut engager la responsabilité 
                de l’Éditeur.
                Le site {site} s'engage à mettre en œuvre tous les moyens nécessaires pour garantir 
                la sécurité et la confidentialité des données. Toutefois, il n’apporte pas une 
                garantie de sécurité totale.
            </Paragrah>
            <Paragrah>
                Des espaces interactifs (possibilité de poser des questions dans l’espace contact) 
                sont à la disposition des utilisateurs. L’Éditeur se réserve le droit de supprimer, 
                sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait 
                à la législation applicable en France, en particulier aux dispositions relatives 
                à la protection des données. 
                Le cas échéant, l’Éditeur se réserve également la possibilité de mettre en cause la 
                responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à 
                caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support 
                utilisé (texte, photographie, ...).
            </Paragrah>


            <Title>Propriété intellectuelle</Title>

            <Paragrah>
                L’Éditeur est propriétaire des droits de propriété intellectuelle ou détient les 
                droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, 
                éléments graphiques, logo, sons, logiciels.
            </Paragrah>
            <Paragrah>
                L’Utilisateur devra obtenir l’autorisation écrite de l’Éditeur avant toute reproduction, 
                copie ou publication de ces différents contenus. Ces derniers peuvent être utilisés 
                par les utilisateurs à des fins privées ; tout usage commercial est interdit.
            </Paragrah>
            <Paragrah>
                Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il 
                contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément 
                aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
            </Paragrah>


            <Title>Données personnelles et cookies</Title>

            <Paragrah>
                En France, les données personnelles sont notamment protégées par la loi 
                n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, 
                l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
            </Paragrah>
            <Paragrah>
                En tout état de cause l’Éditeur ne collecte des informations relatives à l'Utilisateur 
                que pour suivre le volume, le type et la configuration du trafic 
                utilisant {site} pour en développer la conception et l'agencement et plus généralement 
                pour améliorer le service offert. 
            </Paragrah>
            <Paragrah>
                Concernant les statistiques, c’est le service <a href="https://marketingplatform.google.com/about/analytics/terms/fr/" target="_blank" rel="noopener noreferrer">Analytics de Google</a> qui s’en charge.
            </Paragrah>
            <Paragrah>
                Le site n'est pas déclaré à la CNIL.
            </Paragrah>


            <Title>Liens hypertextes</Title>

            <Paragrah>
                Le site {site} peut contenir des liens hypertexte donnant accès à d'autres 
                sites web édités et gérés par des tiers et non par l'Éditeur. 
                L'Éditeur ne pourra être tenu responsable directement ou indirectement dans 
                le cas où lesdits sites tiers ne respecteraient pas les dispositions légales.
            </Paragrah>
            <Paragrah>
                Il est possible pour un tiers de créer un lien vers une page du 
                site {site} sans autorisation expresse de l’Éditeur.
            </Paragrah>


            <Title>Évolution des conditions générales d’utilisation</Title>

            <Paragrah>
                Le site {site} se réserve le droit de modifier les clauses de ces conditions 
                générales d’utilisation à tout moment et sans justification.
            </Paragrah>

            
            <Title>Durée du contrat</Title>

            <Paragrah>
                La durée du présent contrat est indéterminée. Le contrat produit ses effets 
                à l'égard de l'Utilisateur à compter du début de l’utilisation du service.
            </Paragrah>

        </React.Fragment>
    );
}

export default Cgu;