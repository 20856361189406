import React from "react";
import { Guid } from "guid-typescript";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    List, ListItem, ListItemIcon,
    Checkbox, Collapse, Typography,
} from "@material-ui/core";

import Spacer from "../Spacer";
import Dialog from "../Dialog";
import { ArrowDownIcon, ArrowUpIcon, FilterIcon } from "../../App";
import { FilterableActivityType } from "../../model/response";

const ActivityTypesFilterDialogStyles = (theme: Theme) => createStyles({
    dialogContent: {
        padding: 0,
    },
    arrowIcon: {
        wdth: theme.spacing(4),
        minWidth: theme.spacing(4),
        maxWidth: theme.spacing(4),
    },
    listItem: {
        padding: theme.spacing(0, 2),
    },
    nested: {
        marginLeft: theme.spacing(6),
    },
});

export interface ActivityTypesFilterDialogProps {
    open: boolean,
    activityTypes: FilterableActivityType[],
    selectedActivityTypesId: Guid[],
    onClose: (() => void),
    onSelectionChange: ((ids: Guid[]) => void),
}

interface FitlerableActivityGroup {
    id: Guid,
    name: string,
    collapsed: boolean,
    activityTypes: FilterableActivityType[],
}

type ActivityTypesFilterDialogStyledProps = WithStyles<typeof ActivityTypesFilterDialogStyles>;
type ActivityTypesFilterDialogCombinedProps = ActivityTypesFilterDialogProps & ActivityTypesFilterDialogStyledProps;

interface ActivityTypesFilterDialogCombinedState {
    activityGroups: FitlerableActivityGroup[],
}

class ActivityTypesFilterDialog extends React.Component<ActivityTypesFilterDialogCombinedProps, ActivityTypesFilterDialogCombinedState> {

    constructor(props: ActivityTypesFilterDialogCombinedProps) {
        super(props);
        this.state = {
            activityGroups: [],
        }
    }

    componentDidMount() {
        this.updateFitlerableActivityGroup(
            this.props.activityTypes,
            this.props.selectedActivityTypesId);
    }

    componentWillReceiveProps(newProps: ActivityTypesFilterDialogCombinedProps) {
        this.updateFitlerableActivityGroup(
            newProps.activityTypes,
            newProps.selectedActivityTypesId);
    }

    updateFitlerableActivityGroup = (
        activityTypes: FilterableActivityType[],
        selectedActivityTypesId: Guid[]) => {

        let activityGroups = [] as FitlerableActivityGroup[];
        activityTypes.forEach(at => {
            let g = activityGroups.find(x => x.name === at.activityGroupName)
            let a = Object.assign({}, at) as FilterableActivityType;
            if (g === undefined) {
                g = {
                    id: Guid.create(),
                    name: at.activityGroupName,
                    collapsed: false,
                    activityTypes: [],
                }
                activityGroups.push(g);
            }
            const isSelected = selectedActivityTypesId.find(x => x.toString() === a.id.toString()) !== undefined;
            a.isSelected = isSelected;

            g.activityTypes.push(a);
            g.collapsed = this.isAllActivityTypesUnselected(g);
        });
        this.setState({ activityGroups })
    }

    isAllActivityTypesSelected = (group: FitlerableActivityGroup): boolean => {
        return group.activityTypes.reduce((acc: boolean, at: FilterableActivityType) => acc && at.isSelected, true);
    }

    isAllActivityTypesUnselected = (group: FitlerableActivityGroup): boolean => {
        return group.activityTypes.reduce((acc: boolean, at: FilterableActivityType) => acc && !at.isSelected, true);
    }

    handleCollapseGroup = (agId: Guid) => {
        let { activityGroups } = this.state;
        let ag = activityGroups.find(x => x.id.toString() === agId.toString());
        if (ag === undefined) {
            return;
        }
        ag.collapsed = !ag.collapsed;
        this.setState({ activityGroups });
    }

    handleToggleGroup = (e: any, agId: Guid) => {
        e.preventDefault();
        e.stopPropagation();
        let { activityGroups } = this.state;
        let ag = activityGroups.find(x => x.id.toString() === agId.toString());
        if (ag === undefined) {
            return;
        }
        const isSelected = this.isAllActivityTypesSelected(ag);
        ag.activityTypes.forEach(x => x.isSelected = !isSelected);
        this.setState({ activityGroups });
    }

    handleToggleType = (agId: Guid, atId: Guid) => {
        let { activityGroups } = this.state;
        let ag = activityGroups.find(x => x.id.toString() === agId.toString());
        if (ag === undefined) {
            return;
        }
        let at = ag.activityTypes.find(x => x.id.toString() === atId.toString());
        if (at === undefined) {
            return;
        }
        at.isSelected = !at.isSelected;
        this.setState({ activityGroups });
    }

    handleChange = () => {
        let selectedIds = [] as Guid[];
        this.state.activityGroups.forEach(ag => {
            ag.activityTypes.forEach(at => {
                if (at.isSelected === true) {
                    selectedIds.push(at.id);
                }
            })
        })
        this.props.onSelectionChange(selectedIds);
    }

    render() {
        const { open, onClose, classes } = this.props;
        const { activityGroups } = this.state;

        return (
            <Dialog
                id="filterable-activity-types-dialog"
                open={open}
                onClose={onClose}
                actionLabel="Filter"
                onAction={this.handleChange}
                title="Filtrer les types d'activités"
                titleIcon={<FilterIcon />}
                classes={{
                    dialogContent: classes.dialogContent,
                }}
            >
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {activityGroups.map(ag =>
                        <React.Fragment key={ag.id.toString()}>

                            <ListItem
                                button
                                className={classes.listItem}
                                onClick={() => this.handleCollapseGroup(ag.id)}
                            >
                                <ListItemIcon className={classes.arrowIcon}>
                                    {ag.collapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                                </ListItemIcon>
                                <Typography component="span" noWrap={true}>
                                    {`${ag.name} (${ag.activityTypes.length})`}
                                </Typography>
                                <Spacer />
                                <Checkbox
                                    color="primary"
                                    onClick={(e: any) => this.handleToggleGroup(e, ag.id)}
                                    checked={this.isAllActivityTypesSelected(ag)}
                                />
                            </ListItem>

                            <Collapse in={!ag.collapsed} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding={true}>
                                    {ag.activityTypes.map(a =>
                                        <ListItem
                                            button
                                            key={a.id.toString()}
                                            className={classes.listItem}
                                            onClick={() => this.handleToggleType(ag.id, a.id)}
                                        >
                                            <Typography className={classes.nested} component="span" noWrap={true}>
                                                {a.name}
                                            </Typography>
                                            <Spacer />
                                            <Checkbox
                                                color="primary"
                                                checked={a.isSelected}
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </Collapse>

                        </React.Fragment>
                    )}
                </List>

            </Dialog>
        )
    }
}

export default withStyles(ActivityTypesFilterDialogStyles, { withTheme: true })(ActivityTypesFilterDialog);