import React from "react";
import { BrowserRouter } from "react-router-dom";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import theme from "./App.theme";
import App from "./App";

function AppContainer() {

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />

        <App />

      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default AppContainer;
