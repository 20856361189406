import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { DayActivities, Month } from "../../model/response";
import { TimelineDayActivity } from "../../components/Timeline";
import { TimelineDayRow, TimelineMonthRow, TimelineRowSeparator } from "../../components/Timeline";
import { numberOfDaysBetween, numberOfMonthBetween } from "../../tools/moment";
import { Typography } from "@material-ui/core";

const Styles = (theme: Theme) => createStyles({
    emptyActivities: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(1),
        color: theme.palette.text.hint,
    },
});

export interface Props {
    timelineMonths: Month[],
    dayActivities: DayActivities[],
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function TimelineActivities(props: CombinedProps) {

    const { classes, dayActivities, timelineMonths } = props;

    return (
        <React.Fragment>

            {dayActivities.map((da, idx) => {

                const isPrevious = idx > 0
                    ? numberOfDaysBetween(da.date, dayActivities[idx - 1].date) < 2
                    : true;

                const isSameMonth = idx > 0
                    ? numberOfMonthBetween(da.date, dayActivities[idx - 1].date) === 0
                    : true;

                return (
                    <React.Fragment key={idx}>
                        {isPrevious && isSameMonth ? null : <TimelineRowSeparator />}
                        {isSameMonth ? null : <TimelineMonthRow date={da.date} months={timelineMonths} />}
                        <TimelineDayRow day={da.date}>
                            {da.activities.length > 0
                                ? da.activities.map((a, adx) =>
                                    <TimelineDayActivity day={da.date} activity={a} key={adx} />
                                )
                                :
                                [
                                    <Typography
                                        component="div"
                                        variant="body2"
                                        className={classes.emptyActivities}
                                        key={0}
                                    >
                                        Aucune activité trouvée.
                                        <br />
                                        Modifier le filtre pour trouver votre bonheur ...
                                    </Typography>
                                ]
                            }
                        </TimelineDayRow>
                    </React.Fragment>
                )
            })}

        </React.Fragment>
    );
}

export default withStyles(Styles, { withTheme: true })(TimelineActivities);