import React from "react";

import { AppBar } from "@material-ui/core";

import MenubarDesktop from "./Menubar.desktop";
import MenubarMobile from "./Menubar.mobile";
import ToolbarMobile from "./Toolbar.mobile";
import { OnDesktop, OnMobile } from "../../components/Hidden";
import { MainToolbarDesktop, ToolbarSpacer } from "../../components/Toolbar";
import Container from "../../components/Container";
import { MenubarSpacerMobile } from "../../components/Menubar";
import Footer from "../../components/Footer";
import { ContactFormPaper } from "../../components/ContactForm";
import Snackbar from "../../components/Snackbar";

export interface Props {
    onOpenDrawer: (() => void),
}

export interface State {
    confirmationIsOpen: boolean,
}

class ContactPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            confirmationIsOpen: false,
        }
    }

    componentDidMount() {
        document.title = "Contact - Ferias40";
        
        window.scrollTo({ top: 0 });
    }

    openConfirmation = () => this.setState({ confirmationIsOpen: true });
    closeConfirmation = () => this.setState({ confirmationIsOpen: false });

    render() {

        const { onOpenDrawer } = this.props;
        const { confirmationIsOpen } = this.state;

        return (
            <React.Fragment>

                <AppBar position="fixed">

                    <OnDesktop>
                        <MainToolbarDesktop />
                        <MenubarDesktop />
                    </OnDesktop>

                    <OnMobile>
                        <ToolbarMobile
                            onOpenDrawer={onOpenDrawer}
                        />
                        <MenubarMobile />
                    </OnMobile>

                </AppBar>

                <Container>

                    <ToolbarSpacer />
                    <MenubarSpacerMobile />

                    <ContactFormPaper
                        onAfterSend={this.openConfirmation}
                    >
                        <p>
                            Besoin d'un renseignement, ou vous souhaitez nous aider à ajouter des informations au site ?
                    </p>
                        <p>
                            N'hésitez pas à nous contacter via le formulaire ci-dessous
                            en renseignant votre email pour que nous puissions vous répondre.
                    </p>
                    </ContactFormPaper>

                    <OnDesktop>
                        <Footer />
                    </OnDesktop>

                    <Snackbar 
                        open={confirmationIsOpen}
                        onClose={this.closeConfirmation}
                        message="Votre demande a bien envoyée"
                    />

                </Container>

            </React.Fragment>
        );
    }
}

export default ContactPage;