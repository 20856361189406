import ApiRequestName from "./ApiRequestName";
import ApiRequestConfiguration from "./ApiRequestConfiguration";

const defaultApiRequestConfiguration = new ApiRequestConfiguration()
    .get(ApiRequestName.ListTimelineDays, "/timeline-days", false)
    .get(ApiRequestName.ListTimelineMonths, "/timeline-months", false)
    .get(ApiRequestName.TimelineEvents, "timeline/events", false)
    .get(ApiRequestName.TimelineActivities, "timeline/activities", false)
    .get(ApiRequestName.SearchEvents, "events", false)
    .get(ApiRequestName.ListFilterableActivityTypes, "/activity-types/filterable", false)
    .get(ApiRequestName.EventProgram, "/events/:departmentSlug/:townSlug/:eventTypeSlug/program")
    .get(ApiRequestName.ImageGet, "/images/:id")
    .post(ApiRequestName.Contact, "/contact")
    ;

export default defaultApiRequestConfiguration;