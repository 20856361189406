import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { IconButton, Typography } from "@material-ui/core";

import { ToolbarMobile as CToolbarMobile } from "../../components/Toolbar";
import {
    ArrowLeftIcon,
} from "../../App";
import { Event } from "../../model/response";
import { history } from "../../tools/History";

const Styles = (theme: Theme) => createStyles({
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginLeft: theme.spacing(1),
    },
    title: {
        fontSize: 18,
    },
    subtitle: {
        marginTop: -5,
        fontSize: 14,
    },
    backBtn: {
        "& svg": {
            marginLeft: theme.spacing(1),
        }
    }
})

export interface Props {
    loading: boolean,
    eventProgram?: Event,
}

type StyledProps = WithStyles<typeof Styles>;
type RouterProps = RouteComponentProps<any>;
export type CombinedProps = Props & StyledProps & RouterProps;

function ToolbarMobile(props: CombinedProps) {

    const goBack = () => {
        const page = history.getPreviousProgram();
        props.history.push(page);
    }

    const { classes, loading, eventProgram } = props;

    let title = "";
    let subtitle = undefined;

    if (loading) {
        title = "Chargement ..."
    }
    else if (eventProgram) {
        title = eventProgram.townName;
        subtitle = eventProgram.eventTypeName;
    }

    return (
        <CToolbarMobile>

            <IconButton
                color="inherit"
                size="small"
                aria-label="Back"
                className={classes.backBtn}
                onClick={goBack}
            >
                <ArrowLeftIcon />
            </IconButton>

            <div className={classes.titleContainer}>

                <Typography variant="h6" noWrap={true} className={classes.title}>
                    {title}
                </Typography>

                {subtitle
                    ?
                    <Typography variant="body2" noWrap={true} className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                    : null
                }

            </div>

        </CToolbarMobile>

    );
}

export default withRouter(
    withStyles(Styles, { withTheme: true })(ToolbarMobile)
);
