import RouteName, { toString } from "./RouteName"

const ROUTE_BASE = ""

export default class Route {

    private _name: RouteName;
    private _path: string;
    private _hash: string;

    constructor(name: RouteName, path: string, hash: string = "") {
        this._name = name;
        this._path = path;
        this._hash = hash;
    }

    public clone(): Route {
        return new Route(this._name, this._path, this._hash);
    }

    public name(): string {
        return toString(this._name);
    }
    
    public fullPath(): string {
        return ROUTE_BASE + this._path + (this._hash ? `#${this._hash}` : "");
    }

    public isParameters(): boolean {
        return this._path.indexOf(":") !== -1;
    }

    public setParameters(params: { [key: string]: string }): Route {
        if (!this.isParameters()) {
            throw Error(`Route "${toString}" doesn"t need parameters`);
        }

        Object.keys(params).forEach(k => {
            const id = ":" + k;
            const value = params[k];
            this._path = this._path.replace(id, value);
        })

        if (this.isParameters()) {
            throw Error(`Route named "${this.name()}" has some parameters not been setted`);
        }

        return this;
    }

    public setHash(hash: string): Route {
        this._hash = hash;
        return this;
    }

}
