import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { CircularProgress, Typography } from "@material-ui/core";

import EventDaysTabs from "./EventDaysTabs";
import { MenubarDesktop as CMenubarDesktop } from "../../components/Menubar";
import { ErrorIcon } from "../../App";
import { getDatesRangeLabel } from "../../tools/moment";
import { Event } from "../../model/response";

const Styles = (theme: Theme) => createStyles({
    menubarRowCollapse: {
        padding: 0,
        flexDirection: "column",
    },
    menubarRowCenter: {
        flexDirection: "row",
        alignItems: "center",
    },
    loading: {
        marginLeft: "auto",
        marginRight: "auto",
        "& svg": {
            color: theme.palette.background.default,
        },
    },
    datesUnvalide: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
});

export interface Props {
    loading: boolean,
    eventProgram?: Event,
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

class MenubarDesktop extends React.Component<CombinedProps> {

    render() {
        const { classes, loading, eventProgram } = this.props;
        
        let title = "";
        let subTitle = undefined;

        if (!loading && eventProgram) {
            title = `${eventProgram.townName}, ${eventProgram.eventTypeName}`;
            subTitle = getDatesRangeLabel(eventProgram.startAt, eventProgram.endAt, true);
        }
        else if (!loading && !eventProgram) {
            title = "Erreur lors du chargement de la fête !";
        }
        else if (loading) {
            title = "Chargement de la fête ..."
        }

        const areDatesValidated = eventProgram ? eventProgram.areDatesValidated : true;

        let centerClass = undefined as string | undefined;
        if (loading || !areDatesValidated) {
            centerClass = classes.menubarRowCenter;
        }

        return (
            <CMenubarDesktop
                title={title}
                subtitle={subTitle}
                classes={{ 
                    rowCollapse: classnames(classes.menubarRowCollapse, centerClass),
                }}
            >

                {loading
                    ?
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                    :
                    <React.Fragment></React.Fragment>
                }

                {!loading && !areDatesValidated
                    ?
                    <Typography variant="h6" className={classes.datesUnvalide}>
                        <ErrorIcon />
                        Dates non validées
                    </Typography>
                    :
                    <React.Fragment></React.Fragment>
                }

                {!loading && areDatesValidated
                    ?
                    <EventDaysTabs
                        days={eventProgram ? eventProgram.days : []}
                        tabsVariant={eventProgram && eventProgram.days.length > 5 ? "scrollable" : "fullWidth"}
                        tabsScrollButtons={eventProgram && eventProgram.days.length > 5 ? "on" : "desktop"}
                    />
                    :
                    <React.Fragment></React.Fragment>
                }

            </CMenubarDesktop>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(MenubarDesktop);