import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const MenubarSpacerDesktopStyles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flex: "1 1 auto",
        height: 112,
        minHeight: 112,
        maxHeight: 112,
    },
});


type MenubarSpacerDesktopStyledProps = WithStyles<typeof MenubarSpacerDesktopStyles>;
export type MenubarSpacerDesktopCombinedProps = MenubarSpacerDesktopStyledProps;

function MenubarSpacerDesktop(props: MenubarSpacerDesktopCombinedProps) {

    const { classes } = props;

    return (
        <div className={classes.root}></div>
    );
}

export default withStyles(MenubarSpacerDesktopStyles, { withTheme: true })(MenubarSpacerDesktop);


// function Menubar