import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { TimelineRow } from ".";

const TimelineRowSeparatorStyles = (theme: Theme) => createStyles({
    separator: {
        borderRightStyle: "dotted",
    },
});

interface TimelineRowSeparatorProps {
}

type TimelineRowSeparatorStyledProps = WithStyles<typeof TimelineRowSeparatorStyles>;
export type TimelineRowSeparatorCombinedProps = TimelineRowSeparatorProps & TimelineRowSeparatorStyledProps;

function TimelineRowSeparator(props: TimelineRowSeparatorCombinedProps) {
    const { classes } = props;

    return (
        <TimelineRow
            classes={{ separator: classes.separator }}
        />
    )
}

export default withStyles(TimelineRowSeparatorStyles, { withTheme: true })(TimelineRowSeparator);