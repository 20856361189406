import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { CircularProgress } from "@material-ui/core";

import { MenubarDesktop as CMenubarDesktop } from "../../components/Menubar";
import ButtonIcon from "../../components/ButtonIcon";
import Spacer from "../../components/Spacer";
import { SearchEventInput, SearchEventResults } from "../../components/SearchEvent";
import { CalendarIcon, ArrowDownIcon, SearchIcon } from "../../App";

import { Event } from "../../model/response";
import { routeFactory, RouteName } from "../../tools/route";

const Styles = (theme: Theme) => createStyles({
    menubarRowCollapse: {
        overflow: "visible",
    },
    monthBtn: {
        textTransform: "capitalize",
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: fade(theme.palette.common.white, 0.90),
        width: "auto",
        "&:hover": {
            color: fade(theme.palette.common.white, 1),
        },
    },
    searchContainer: {
        position: "relative",
    },
    searchInputInput: {
        width: 210,
    },
    progressIcon: {
        maxWidth: theme.spacing(3),
        width: theme.spacing(3),
        maxHeight: theme.spacing(3),
        height: theme.spacing(3),
    },
    resultsContainer: {
        position: "absolute",
        top: 48,
        right: 0,
        width: "100%",
        maxHeight: 350,
    },
});

const SEARCH_RESULT_ITEM_CLASS = "search-result-event-item";

export interface Props {
    onDatePicker: (() => void),
    datepickerLabel: string,
    onSearchEvent: ((search: string) => void),
    searchEventLoading: boolean,
    searchEventResults: Event[] | undefined,
}

type StyledProps = WithStyles<typeof Styles>;
type RouterProps = RouteComponentProps<any>;
export type CombinedProps = Props & StyledProps & RouterProps;

export interface State {
    focused: boolean,
}

class MenubarDesktop extends React.Component<CombinedProps, State> {

    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: CombinedProps) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            focused: false,
        }
    }

    setSearchFocused = (focused: boolean) => {
        this.setState({ focused });
    }

    handleGoEvent = (event: Event) => {
        const route = routeFactory
            .get(RouteName.EventProgram)
            .setParameters({
                departmentSlug: event.departmentSlug,
                townSlug: event.townSlug,
                eventTypeSlug: event.eventTypeSlug,
            })
            .fullPath();
        this.props.history.push(route);
    }

    render() {
        const {
            classes, onDatePicker, datepickerLabel,
            onSearchEvent, searchEventLoading, searchEventResults
        } = this.props;

        const { focused } = this.state;

        const rightIcon = searchEventLoading
            ? <CircularProgress className={classes.progressIcon} />
            : <SearchIcon />;
        const clearable = !searchEventLoading;

        let isSearchQuery = false;
        if (this.inputRef.current) {
            isSearchQuery = this.inputRef.current.value.length > 0;
        }

        return (
            <CMenubarDesktop
                title="Calendrier des fêtes"
                subtitle="Agenda des fêtes et ferias des Landes, Sud-Ouest"
                classes={{
                    rowCollapse: classes.menubarRowCollapse,
                }}
            >

                <ButtonIcon
                    leftIcon={<CalendarIcon />}
                    rightIcon={<ArrowDownIcon />}
                    className={classes.monthBtn}
                    onClick={onDatePicker}
                >
                    {datepickerLabel}
                </ButtonIcon>

                <Spacer />

                <div className={classes.searchContainer}>

                    <SearchEventInput
                        inputRef={this.inputRef}
                        rightIcon={rightIcon}
                        clearable={clearable}
                        onChange={onSearchEvent}
                        onFocus={() => this.setSearchFocused(true)}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            const target = e.nativeEvent.relatedTarget as HTMLDivElement | null;
                            if (!target || !target.classList.contains(SEARCH_RESULT_ITEM_CLASS)) {
                                this.setSearchFocused(false);
                            }
                        }}
                        classes={{
                            inputInput: classes.searchInputInput,
                        }}
                    />

                    {
                        focused && isSearchQuery && searchEventResults !== undefined
                            ?
                            <SearchEventResults
                                events={searchEventResults}
                                identifierClass={SEARCH_RESULT_ITEM_CLASS}
                                classes={{
                                    container: classes.resultsContainer,
                                }}
                            />
                            :
                            null
                    }

                </div>

            </CMenubarDesktop>
        );
    }
}

export default withRouter(
    withStyles(Styles, { withTheme: true })(MenubarDesktop)
);