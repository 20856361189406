import React from "react";
import { Link } from "react-router-dom";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

import moment from "../../tools/moment";
import { routeFactory, RouteName } from "../../tools/route";
import { DevicesIcon } from "../../App";

const FooterStyles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        padding: theme.spacing(5, 0),
        color: theme.palette.grey[700],
        textAlign: "center",
        alignItems: "center",
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        alignItems: "center",
        "& a": {
            color: "inherit",
            textDecoration: "underline",
        }
    },
    deviceIcons: {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        margin: theme.spacing(0, 1),
    }
});


type FooterStyledProps = WithStyles<typeof FooterStyles>;
export type FooterCombinedProps = FooterStyledProps;

function Footer(props: FooterCombinedProps) {

    const { classes } = props;

    return (
        <footer className={classes.container}>
            <Typography variant="body2" component="div"
                className={classes.footer}
             >
                Ferias40 © {moment().format("YYYY")}
                
                &nbsp;-&nbsp;
                
                <a href={routeFactory.fullPath(RouteName.Facebook)} target="_blank" rel="noopener noreferrer">
                    Facebook
                </a>
                
                <div className={classes.deviceIcons}>
                    <DevicesIcon />
                </div>

                <Link to={routeFactory.fullPath(RouteName.Cgu)}>
                    CGU et mentions légales
                </Link>
            </Typography>
        </footer>
    );
}

export default withStyles(FooterStyles, { withTheme: true })(Footer);
