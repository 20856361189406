enum ApiRequestName {
    ListTimelineDays,
    ListTimelineMonths,
    TimelineEvents,
    TimelineActivities,
    SearchEvents,
    ListFilterableActivityTypes,
    EventProgram,
    ImageGet,
    Contact,
}

export default ApiRequestName;

export function toString(name: ApiRequestName): string {
    return ApiRequestName[name];
}
