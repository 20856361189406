import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import HideOnScroll from "../HideOnScroll";
import Container from "../Container";

const MenubarMobileStyles = (theme: Theme) => createStyles({
    containerRoot: {
        backgroundColor: theme.palette.primary.dark,
    },
    rowCollapse: {
        height: 56,
        maxHeight: 56,
        minHeight: 56,
        padding: 0,
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            margin: 0,
        },
    },
});


export interface MenubarMobileProps {
    collapsedHeight?: number,
    children: React.ReactElement | (React.ReactElement)[],
}

type MenubarMobileStyledProps = WithStyles<typeof MenubarMobileStyles>;
export type MenubarMobileCombinedProps = MenubarMobileProps & MenubarMobileStyledProps;

function MenubarMobile(props: MenubarMobileCombinedProps) {

    const { collapsedHeight, classes, children } = props;

    return (
        <HideOnScroll collapsedHeight={collapsedHeight}>

            <Container classes={{
                root: classes.containerRoot,
                container: classes.rowCollapse
            }}>
                {children}
            </Container>

        </HideOnScroll>
    );
}

export default withStyles(MenubarMobileStyles, { withTheme: true })(MenubarMobile);
