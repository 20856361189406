import moment from "../moment";

class TimelineDayElement {

    public element: HTMLElement;
    public month: string;
    public date: Date;

    constructor(elt: Element) {
        this.element = elt as HTMLElement;

        const m = moment(this.id);
        this.date = m.toDate();
        this.month = m.format("MMMM");
    }

    get id(): string {
        return this.element.id;
    }

    get offsetTop(): number {
        const parentTop = this.element.parentElement ? this.element.parentElement.offsetTop : 0;
        return this.element.offsetTop - parentTop;
    }

    get height():number {
        return this.element.getBoundingClientRect().height;
    }

    get top(): number {
        return this.element.getBoundingClientRect().top;
    }
}


class TimelineObserver {

    private _containerRef: React.RefObject<HTMLDivElement>;
    private _containerOffsetTop: number;

    private _monthSelector: string;
    private _timelineMonths: TimelineDayElement[] | undefined;

    private _daySelector: string;
    private _timelineDays: TimelineDayElement[] | undefined;

    private _firstElementDate: moment.Moment | undefined;
    private _nbElement: number;

    constructor(
        ref: React.RefObject<HTMLDivElement>,
        monthSelector: string,
        daySelector: string) {

        this._containerRef = ref;
        this._containerOffsetTop = this.getContainerOffsetTop();

        this._monthSelector = monthSelector;
        this._timelineMonths = undefined;
        this._firstElementDate = undefined;

        this._daySelector = daySelector;
        this._timelineDays = undefined;

        this._nbElement = 0;

        this.updateMonths();
    }

    private _updateDateElement(selector: string): TimelineDayElement[] {
        if (this._containerRef.current) {
            const nodeList = this._containerRef.current.querySelectorAll(selector);
            const items = Array.from(nodeList);
            return items.map(x => new TimelineDayElement(x));
        }
        return [];
    }

    public updateMonths() {
        this._timelineMonths = this._updateDateElement(this._monthSelector);
    }

    public updateDays() {
        this._timelineDays = this._updateDateElement(this._daySelector);
    }

    public setDayElements(elements: { date: Date }[]) {
        const nbElement = elements.length;
        if (this._nbElement !== nbElement) {
            this._timelineMonths = undefined;
            this._timelineDays = undefined;
        }

        this._nbElement = nbElement;

        this._firstElementDate = undefined;
        if (nbElement > 0) {
            this._firstElementDate = moment(elements[0].date);
        }
    }

    public getContainerOffsetTop(): number {
        return this._containerRef.current ? this._containerRef.current.offsetTop : 120;
    }

    public getTopMonth(): string {
        if (this._timelineMonths === undefined) {
            this._timelineMonths = [];
            this.updateMonths();
        }

        if (this._timelineMonths.length === 0) {
            if (this._firstElementDate !== undefined) {
                return this._firstElementDate.format("MMMM");
            }
        }
        else {
            const monthsTop = this._timelineMonths.map(x => x.top);
            let bestTop = monthsTop[0];
            let bestIdx = 0;
            monthsTop.forEach((t, idx) => {
                if (t < this._containerOffsetTop && t > bestTop) {
                    bestIdx = idx;
                    bestTop = t;
                }
            });

            if (bestTop > this._containerOffsetTop) {
                if (this._firstElementDate) {
                    return this._firstElementDate.format("MMMM");
                }
            }
            else {
                return this._timelineMonths[bestIdx].month;
            }
        }

        return moment().format("MMMM");
    }

    public getTopDay(): Date {
        if (this._timelineDays === undefined) {
            this._timelineDays = [];
            this.updateDays();
        }

        if (this._timelineDays.length > 0) {
            const elt = this._timelineDays.find(x => {
                const start = x.top;
                const end = start + x.height / 2;
                return start > this._containerOffsetTop || end > this._containerOffsetTop
            });
            if (elt) {
                return elt.date;
            }
        }

        return new Date();
    }

    public findDayElement(id: string): TimelineDayElement | undefined {
        if (this._timelineDays === undefined) {
            this._timelineDays = [];
            this.updateDays();
        }

        if (this._timelineDays !== undefined) {
            return this._timelineDays.find(x => x.id === id);
        }
        return undefined;
    }

    public getValidTimelineToday(date: Date): moment.Moment {
        let res = moment(date);
        const first = this._firstElementDate ? this._firstElementDate.clone() : moment();
        res = res.isSameOrBefore(first) ? first : res;
        return res;
    }

    public scrollTo(element: TimelineDayElement): void {
        const top = element.offsetTop;
        window.scrollTo({ top: top });
    }
}


export default TimelineObserver;

export {
    TimelineDayElement as TimelineMonthObserver,
};