import React from "react";

import TimelineDayItem from "./TimelineDayItem";
import { Activity } from "../../model/response";
import moment, { dateHashFormat } from "../../tools/moment";
import { routeFactory, RouteName } from "../../tools/route";

interface TimelineDayActivityProps {
    day: Date,
    activity: Activity,
}

function TimelineDayActivity(props: TimelineDayActivityProps) {
    const { day, activity } = props;

    const mDay = moment(day);
    const hash = `${mDay.format(dateHashFormat)}_${activity.time}`;

    const href = routeFactory
        .get(RouteName.EventProgram)
        .setParameters({
            departmentSlug: activity.departmentSlug,
            townSlug: activity.townSlug,
            eventTypeSlug: activity.eventTypeSlug,
        })
        .setHash(hash)
        .fullPath();

    // TODO changer l'url pour pouvoir passer une heure précise du jour
    // et modifier le eventprogrm pour scroller automatiquement sur l'heure désirée

    const title = `${activity.time} - ${activity.name}`;
    const subtitle = `${activity.townName}`;

    return (
        <TimelineDayItem
            label={`${title} à ${subtitle}`}
            title={title}
            subtitle={subtitle}
            href={href}
            coverId={activity.coverId}
        />
    )
}

export default TimelineDayActivity;
