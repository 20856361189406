import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import { Typography } from "@material-ui/core";

import { MenubarMobile as CMenubarMobile } from "../../components/Menubar";

const Styles = (theme: Theme) => createStyles({
    menubar: {
        padding: theme.spacing(1, 3),
        flexDirection: "column",
        justifyContent: "space-around",
    },
});

type StyledProps = WithStyles<typeof Styles>;

function MenubarMobile(props:StyledProps) {
    
    const { classes } = props;

    return (
        <CMenubarMobile
            classes={{
                rowCollapse: classes.menubar
            }}
        >

            <Typography variant="body2" component="span">
                Besoins de renseignements ? Contactez nous
            </Typography>

        </CMenubarMobile>
    );
}

export default withStyles(Styles, { withTheme: true })(MenubarMobile);