import React from "react";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { MenubarDesktop as CMenubarDesktop } from "../../components/Menubar";
import ButtonIcon from "../../components/ButtonIcon";
import Spacer from "../../components/Spacer";
import { CalendarIcon, ArrowDownIcon, FilterIcon } from "../../App";

const Styles = (theme: Theme) => createStyles({
    btn: {
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: fade(theme.palette.common.white, 0.90),
        borderColor: "inherit",
        width: "auto",
        "&:hover": {
            color: fade(theme.palette.common.white, 1),
        },
    },
});

export interface Props {
    onDatePicker: (() => void),
    datepickerLabel: string,
    onActivityTypesFilter: (() => void),
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function MenubarDesktop(props: CombinedProps) {

    const {
        classes, onDatePicker, datepickerLabel,
        onActivityTypesFilter
    } = props;

    return (
        <CMenubarDesktop
            title="Calendrier des activités"
            subtitle="Agenda interactif des principales activités proposées durant les fêtes"
        >

            <ButtonIcon
                leftIcon={<CalendarIcon />}
                rightIcon={<ArrowDownIcon />}
                className={classes.btn}
                onClick={onDatePicker}
            >
                {datepickerLabel}
            </ButtonIcon>

            <Spacer />

            <ButtonIcon
                leftIcon={<FilterIcon />}
                rightIcon={<ArrowDownIcon />}
                className={classes.btn}
                onClick={onActivityTypesFilter}
            >
                Filter les activités
            </ButtonIcon>

        </CMenubarDesktop>
    );
}

export default withStyles(Styles, { withTheme: true })(MenubarDesktop);