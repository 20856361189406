import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    Tabs, Tab,
} from "@material-ui/core";

import { MenubarMobile } from ".";
import { routeFactory, RouteName } from "../../tools/route";

const TimelineMenubarMobileStyles = (theme: Theme) => createStyles({
    tabsRoot: {
        width: "100%",
    },
    tabsContainer: {
        height: 56,
    },
    tabsIndicator: {
        backgroundColor: theme.palette.common.white,
    },
    tab: {
        textTransform: "capitalize",
        fontSize: 18,
    }
})

type TimelineMenubarMobileStyledProps = WithStyles<typeof TimelineMenubarMobileStyles>;
type TimelineMenubarMobileRouterProps = RouteComponentProps<any>;
export type TimelineMenubarMobileCombinedProps = TimelineMenubarMobileStyledProps & TimelineMenubarMobileRouterProps;

function TimelineMenubarMobile(props: TimelineMenubarMobileCombinedProps) {

    const setRoute = (name: RouteName) => {
        const route = routeFactory.fullPath(name);
        props.history.push(route);
    }

    const { classes, location } = props;

    const eventsRoute = routeFactory.fullPath(RouteName.TimelineEvents);
    const isEventsRoute = location.pathname === eventsRoute;

    let tab = isEventsRoute ? 0 : 1;

    return (
        <MenubarMobile>

            <Tabs
                variant="fullWidth"
                value={tab}
                onChange={(e: any, route: RouteName) => setRoute(route)}
                classes={{
                    root: classes.tabsRoot,
                    flexContainer: classes.tabsContainer,
                    indicator: classes.tabsIndicator,
                }}
            >
                <Tab className={classes.tab} label="Fêtes" value={RouteName.TimelineEvents} />
                <Tab className={classes.tab} label="Activités" value={RouteName.TimelineActivities} />
            </Tabs>

        </MenubarMobile>
    );
}

export default withRouter(
    withStyles(TimelineMenubarMobileStyles, { withTheme: true })(TimelineMenubarMobile)
);
