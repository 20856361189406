import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Typography, Tooltip, Fab } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import { Activity, EventDay, Event } from "../../model/response";
import {
    TimelineRow,
    TimelineTimeRow,
    TimelineTimeActivity,
    TimelineTimeEndRow,
    TimelineRowSeparator,
    TimelineTimeBusRow
} from "../../components/Timeline";
import Corner from "../../components/Corner";
import { OfficalIcon, FacebookIcon, BusIcon, ContactIcon, ShareIcon } from "../../App";
import { numberOfHoursBetween, isTimeInside } from "../../tools/moment";
import { OnMobile, OnDesktop } from "../../components/Hidden";
import { ContactFormDialogDesktop, ContactFormDialogMobile, Subject, FormProps } from "../../components/ContactForm";
import Snackbar from "../../components/Snackbar";


const Styles = (theme: Theme) => createStyles({
    titleRow: {
        padding: theme.spacing(2, 0),
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
    },
    hide: {
        display: "none",
    },
    speedDialFab: {
        margin: theme.spacing(0, 1, 0, 1),
        width: 40,
        height: 40,
    },
    speedDialAction: {
        color: theme.palette.primary.main
    },
});

export interface Props {
    eventProgram: Event,
    eventDay: EventDay,
}

type StyledProps = WithStyles<typeof Styles>;
type RouterProps = RouteComponentProps<any>;
export type CombinedProps = Props & StyledProps & RouterProps;

export interface State {
    speedDialIsOpen: boolean,
    contactDialogIsOpen: boolean,
    confirmationIsOpen: boolean,
}

interface TimeActivities {
    time: string,
    activities: Activity[],
}

class EventProgram extends React.Component<CombinedProps, State> {

    _busLinkRef: React.RefObject<HTMLAnchorElement>;
    _facebookLinkRef: React.RefObject<HTMLAnchorElement>;
    _officialLinkRef: React.RefObject<HTMLAnchorElement>;

    constructor(props: CombinedProps) {
        super(props);

        this._busLinkRef = React.createRef();
        this._facebookLinkRef = React.createRef();
        this._officialLinkRef = React.createRef();

        this.state = {
            speedDialIsOpen: false,
            contactDialogIsOpen: false,
            confirmationIsOpen: false,
        };
    }

    componentDidMount() {
        const hashParts = this.props.location.hash ? this.props.location.hash.split("_") : [];
        if (hashParts.length === 2) {
            const time = hashParts[1];
            const elt = document.body.querySelector(`.timeline-time[id="${time}"]`) as HTMLDivElement | null;
            if (elt) {
                const parentTop = elt.parentElement ? elt.parentElement.offsetTop : 0;
                const top =  elt.offsetTop - parentTop;
                window.scrollTo({ top: top, behavior: "smooth" });
            }
        }
        else {
            window.scrollTo({ top: 0 });
        }
        window.addEventListener("scroll", this.closeSpeedDial);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.closeSpeedDial);
    }

    componentWillReceiveProps(nextProps: CombinedProps) {
        const hashChanged = this.props.location.hash !== nextProps.location.hash;
        if (hashChanged) {
            window.scrollTo({ top: 0, behavior: "smooth" });

            if (this.state.contactDialogIsOpen) {
                this.closeContactDialog();
            }
        }
    }

    toggleSpeedDial = () => {
        const { speedDialIsOpen } = this.state;
        this.setState({ speedDialIsOpen: !speedDialIsOpen });
    }
    openSpeedDial = () => this.setState({ speedDialIsOpen: true });
    closeSpeedDial = () => {
        if (this.state.speedDialIsOpen) {
            this.setState({ speedDialIsOpen: false })
        }
    };

    openContactDialog = () => this.setState({ contactDialogIsOpen: true, speedDialIsOpen: false });
    closeContactDialog = () => this.setState({ contactDialogIsOpen: false, speedDialIsOpen: false });

    openConfirmation = () => this.setState({ confirmationIsOpen: true });
    closeConfirmation = () => this.setState({ confirmationIsOpen: false });

    renderTimeBus = (busUrl: string, startTime: string, endTime: string): React.ReactElement | null => {
        if (!busUrl) return null;

        const { busTimes } = this.props.eventDay;
        if (!busTimes || busTimes.length === 0) return null;

        const time = busTimes.find(x => isTimeInside(x, startTime, endTime));
        if (time) {
            return <TimelineTimeBusRow busUrl={busUrl} />
        }

        return null;
    }

    renderTimeActivities = (ta: TimeActivities): React.ReactElement => {
        return <TimelineTimeRow time={ta.time}>
            {ta.activities.map((a, adx) => {
                return <TimelineTimeActivity key={adx} activity={a} />
            })}
        </TimelineTimeRow>
    }

    renderFabUrl = (url: string | undefined,
        title: string,
        className: string | undefined,
        icon: React.ReactElement<SvgIconProps>): React.ReactElement => {
        if (url === undefined || url.length === 0) {
            return <React.Fragment></React.Fragment>
        }
        return <Tooltip title={title} color="primary" placement="left">
            <Fab color="primary" size="small" className={className} href={url} target="_blank">
                {icon}
            </Fab>
        </Tooltip>
    }

    openExternalLink = (ref: React.RefObject<HTMLAnchorElement>): void => {
        this.closeSpeedDial();
        if (ref.current) {
            ref.current.click();
        }
    }

    renderSpeedDialAction = (
        tooltipTitle: string,
        tooltipOpen: boolean,
        icon: React.ReactElement<SvgIconProps>,
        onClick: (() => void)): React.ReactElement => {

        const { classes } = this.props;

        return (
            <SpeedDialAction
                tooltipTitle={tooltipTitle}
                tooltipOpen={tooltipOpen}
                icon={icon}
                onClick={onClick}
                className={classes.speedDialAction}
            />
        );
    }

    renderSpeedDial = (tooltipOpen: boolean): React.ReactElement => {
        const { classes } = this.props;
        const { speedDialIsOpen } = this.state;

        return (
            <SpeedDial
                ariaLabel="Actions sur l'évènement"
                direction="up"
                classes={{
                    fab: classes.speedDialFab,
                }}
                icon={speedDialIsOpen ? <SpeedDialIcon /> : <ShareIcon />}
                onClick={this.toggleSpeedDial}
                onMouseEnter={this.openSpeedDial}
                onMouseLeave={this.closeSpeedDial}
                open={speedDialIsOpen}
                onClose={this.closeSpeedDial}
            >

                {this.renderSpeedDialAction("Demande de renseignements", tooltipOpen, <ContactIcon />,
                    this.openContactDialog
                )}

                {this._officialLinkRef.current
                    ? this.renderSpeedDialAction("Site officiel", tooltipOpen, <OfficalIcon />, () => this.openExternalLink(this._officialLinkRef))
                    : null
                }

                {this._facebookLinkRef.current
                    ? this.renderSpeedDialAction("Evènement facebook", tooltipOpen, <FacebookIcon />, () => this.openExternalLink(this._facebookLinkRef))
                    : null
                }

                {this._busLinkRef.current
                    ? this.renderSpeedDialAction("Les bus des ferias", tooltipOpen, <BusIcon />, () => this.openExternalLink(this._busLinkRef))
                    : null
                }

            </SpeedDial>
        );
    }

    render() {
        const { classes, eventProgram, eventDay } = this.props;

        const { contactDialogIsOpen, confirmationIsOpen } = this.state;

        // re-order activity group by time
        let timeActivities = [] as TimeActivities[];
        eventDay.activities.forEach(a => {
            let ta = timeActivities.find(x => x.time === a.time);
            if (!ta) {
                timeActivities.push({
                    time: a.time,
                    activities: [],
                });
                ta = timeActivities[timeActivities.length - 1];
            }
            ta.activities.push(a);
        });

        return (
            <React.Fragment>

                <div>

                    {eventDay.name && timeActivities.length > 0
                        ?
                        <TimelineRow
                        classes={{
                            rightContainer: classes.titleRow,
                        }}
                        >
                            <Typography variant="h6" className={classes.title} color="primary">
                                {eventDay.name}
                            </Typography>
                        </TimelineRow>
                        :
                        <React.Fragment></React.Fragment>
                    }

                    {
                        timeActivities.map((ta, odx) => {
                            const previousActivity = odx > 0 ? timeActivities[odx - 1] : undefined;
                            const isClosestPrevious = previousActivity ? numberOfHoursBetween(ta.time, previousActivity.time) < 3 : true;
                            const previousActivtyTime = previousActivity ? previousActivity.time : "06:00";
                            
                            return <React.Fragment key={odx}>
                                {isClosestPrevious ? null : <TimelineRowSeparator />}
                                {this.renderTimeBus(eventProgram.busUrl, previousActivtyTime, ta.time)}
                                {this.renderTimeActivities(ta)}
                            </React.Fragment>
                        })
                    }

                    <TimelineTimeEndRow time={eventDay.endAt} />
                    
                </div>

                <OnDesktop>
                    <ContactFormDialogDesktop
                        open={contactDialogIsOpen}
                        onClose={this.closeContactDialog}
                        eventId={eventProgram.id}
                        initialValue={{
                            subject: Subject.AskEventInformations,
                            subjectComplement: `${eventProgram.eventTypeName} de ${eventProgram.townName}`,
                        } as FormProps}
                        onAfterSend={() => {
                            this.closeContactDialog();
                            this.openConfirmation();
                        }}
                    />
                </OnDesktop>
                <OnMobile>
                    <ContactFormDialogMobile
                        open={contactDialogIsOpen}
                        onClose={this.closeContactDialog}
                        eventId={eventProgram.id}
                        initialValue={{
                            subject: Subject.AskEventInformations,
                            subjectComplement: `${eventProgram.eventTypeName} de ${eventProgram.townName}`,
                        } as FormProps}
                        onAfterSend={() => {
                            this.closeContactDialog();
                            this.openConfirmation();
                        }}
                    />
                </OnMobile>

                <Snackbar
                    open={confirmationIsOpen}
                    onClose={this.closeConfirmation}
                    message="Votre demande a bien envoyée"
                />

                <Corner>

                    <OnDesktop>
                        {this.renderSpeedDial(false)}
                    </OnDesktop>

                    <OnMobile>
                        {this.renderSpeedDial(true)}
                    </OnMobile>

                    {eventProgram.busUrl
                        ? <a href={eventProgram.busUrl}
                            className={classes.hide}
                            ref={this._busLinkRef}
                            target="_blank"
                            rel="noopener noreferrer"
                        >bus</a>
                        : <React.Fragment></React.Fragment>
                    }

                    {eventProgram.facebookUrl
                        ? <a href={eventProgram.facebookUrl}
                            className={classes.hide}
                            ref={this._facebookLinkRef}
                            target="_blank"
                            rel="noopener noreferrer"
                        >facebook event</a>
                        : <React.Fragment></React.Fragment>
                    }

                    {eventProgram.officialUrl
                        ? <a href={eventProgram.officialUrl}
                            className={classes.hide}
                            ref={this._officialLinkRef}
                            target="_blank"
                            rel="noopener noreferrer"
                        >official</a>
                        : <React.Fragment></React.Fragment>
                    }

                </Corner>

            </React.Fragment>
        );
    }
}

export default withRouter(
    withStyles(Styles, { withTheme: true })(EventProgram)
);