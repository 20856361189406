import React from "react";
import classnames from "classnames";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { InputBase } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import { ClearIcon } from "../../App";

const SearchEventInputStyles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        color: fade(theme.palette.common.white, 0.60),
        backgroundColor: fade(theme.palette.background.default, 0.90),
        width: "100%",
        "&:hover": {
            color: fade(theme.palette.common.white, 0.90),
            backgroundColor: fade(theme.palette.background.default, 1),
        },
    },
    inputRoot: {
        color: "inherit",
        width: "100%",
    },
    inputInput: {
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 2, 1, 2),
        height: theme.spacing(3),
    },
    inputInputLeftIcon: {
        paddingLeft: theme.spacing(7),
    },
    inputInputRightIcon: {
        paddingRight: theme.spacing(7),
    },
    icon: {
        color: theme.palette.primary.main,
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none",
    },
    leftIcon: {
        left: 0,        
    },
    rightIcon: {
        right: 0,
    },
    clickableIcon: {
        pointerEvents: "stroke",
        cursor: "pointer",
    },
});

export interface SearchEventInputProps {
    onChange: ((q: string) => void),
    leftIcon?: React.ReactElement<SvgIconProps>,
    onClickLeftIcon?: ((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void),
    rightIcon?: React.ReactElement<SvgIconProps>,
    onClickRightIcon?: ((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void),
    onFocus?: ((e: React.FocusEvent<HTMLInputElement>) => void),
    onBlur?: ((e: React.FocusEvent<HTMLInputElement>) => void),
    clearable?: boolean,
    timerDelay?: number,
    inputRef?: React.RefObject<HTMLInputElement>,
}

type SearchEventInputStyledProps = WithStyles<typeof SearchEventInputStyles>;
export type SearchEventInputCombinedProps = SearchEventInputProps & SearchEventInputStyledProps;

export interface SearchEventInputState {
    clearable: boolean,
}

class SearchEventInput extends React.Component<SearchEventInputCombinedProps, SearchEventInputState> {

    timer: any;
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: SearchEventInputCombinedProps) {
        super(props);
        this.timer = null;
        this.inputRef = React.createRef();

        this.state = {
            clearable: false,
        }
    }

    getInputRef = (): React.RefObject<HTMLInputElement> => {
        return this.props.inputRef ? this.props.inputRef : this.inputRef;
    }

    handleChange = () => {
        const inputRef = this.getInputRef();
        if (inputRef.current && this.props.clearable) {
            this.setState({ clearable: inputRef.current.value.length > 0 });
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const inputRef = this.getInputRef();
            if (inputRef.current) {
                this.props.onChange(inputRef.current.value);
            }
        }, this.props.timerDelay ? this.props.timerDelay : 300);
    }

    handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            this.handleClear();
        }
    }

    handleClear = () => {
        const inputRef = this.getInputRef();
        if (inputRef.current) {
            inputRef.current.value = "";
            this.handleChange();
            inputRef.current.focus();
        }
    }

    renderIcon = (
        icon: React.ReactElement<SvgIconProps> | undefined,
        onClick: ((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void) | undefined,
        positionClass: string): React.ReactElement => {

        const { classes } = this.props;

        if (!icon) {
            return <React.Fragment></React.Fragment>;
        }

        const clickableIconClass = onClick ? classes.clickableIcon : undefined;
        const className = classnames(classes.icon, positionClass, clickableIconClass);

        return (
            <div
                className={className}
                onClick={onClick}
            >
                {icon}
            </div>
        )
    }

    renderLeftIcon = (): React.ReactElement => {
        const { leftIcon, onClickLeftIcon, classes } = this.props;
        
        return this.renderIcon(leftIcon, onClickLeftIcon, classes.leftIcon);
    }

    renderRightIcon = (): React.ReactElement => {
        let { rightIcon, onClickRightIcon, clearable, classes } = this.props;

        if (clearable && this.state.clearable) {
            rightIcon = <ClearIcon />;
            onClickRightIcon = this.handleClear;
        }

        return this.renderIcon(rightIcon, onClickRightIcon, classes.rightIcon);
    }

    render() {
        const { 
            leftIcon, rightIcon,
            onFocus, onBlur, clearable, classes 
        } = this.props;

        const inputIconLeftClass = leftIcon ? classes.inputInputLeftIcon : undefined;
        const inputIconRightClass = rightIcon || (clearable && this.state.clearable) ? classes.inputInputRightIcon : undefined;
        const inputClass = classnames(classes.inputInput, inputIconLeftClass, inputIconRightClass)

        return (

            <div className={classes.container}>

                {this.renderLeftIcon()}

                <InputBase
                    onChange={this.handleChange}
                    onKeyUp={clearable ? this.handleKeyUp : undefined}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder="Rechercher une fête ..."
                    classes={{
                        root: classes.inputRoot,
                        input: inputClass,
                    }}
                    inputProps={{ "aria-label": "Rechercher une fête" }}
                    inputRef={this.getInputRef()}
                />

                {this.renderRightIcon()}

            </div>
        );
    }
}

export default withStyles(SearchEventInputStyles, { withTheme: true })(SearchEventInput);