import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import {
    TextField, FormControl,
    InputLabel, Select, MenuItem,
    Button, Typography, Input, CircularProgress,
} from "@material-ui/core";
import { PropTypes } from "@material-ui/core";
import Spacer from "../../components/Spacer";
import { SendIcon } from "../../App";

import {
    FormProps,
    Subject,
    subjectList,
    getSubjectLabel,
    getSubjectComplementLabel,
    getSubjectComplementPlaceholder,
} from "../../components/ContactForm";
import { FormError } from "../../model/response";

const Styles = (theme: Theme) => createStyles({
    selectControl: {
        margin: theme.spacing(1, 0, 0.5, 0),
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        marginTop: theme.spacing(2),
    },
    iconBtn: {
        marginLeft: theme.spacing(1),
        maxWidth: 24,
        maxHeight: 24,
    },
    loader: {
        maxWidth: 24,
        maxHeight: 24,
        "& svg": {
            width: 24,
            height: 24,
        }
    },
    note: {
        margin: theme.spacing(1, 0, 0.5, 0),
        "& a": {
            color: theme.palette.primary.main,
        }
    },
    hide: {
        display: "none",
    },
});

export interface Props {
    initialValue?: FormProps,
    errors: FormError[],
    onSubmit: ((formProps: FormProps) => void),
    extraAction?: React.ReactElement,
    loading: boolean,
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

export interface State {
    email: string,
    subject: Subject,
    subjectComplement: string,
    description: string,
}

class ContactForm extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            email: props.initialValue && props.initialValue.email ? props.initialValue.email : "",
            subject: props.initialValue && props.initialValue.subject !== undefined ? props.initialValue.subject : Subject.Other,
            subjectComplement: props.initialValue && props.initialValue.subjectComplement ? props.initialValue.subjectComplement : "",
            description: props.initialValue && props.initialValue.description ? props.initialValue.description : "",
        };
    }

    componentWillReceiveProps(newProps: CombinedProps) {
        this.setState({
            email: newProps.initialValue && newProps.initialValue.email ? newProps.initialValue.email : "",
            subject: newProps.initialValue && newProps.initialValue.subject !== undefined ? newProps.initialValue.subject : Subject.Other,
            subjectComplement: newProps.initialValue && newProps.initialValue.subjectComplement ? newProps.initialValue.subjectComplement : "",
            description: newProps.initialValue && newProps.initialValue.description ? newProps.initialValue.description : "",
        });
    }

    handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({ [name]: value } as any);
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const { email, subject, subjectComplement, description } = this.state;
        const formProps = {
            email, subject, subjectComplement, description
        } as FormProps;

        this.props.onSubmit(formProps);
    }

    render() {

        const { classes, errors, extraAction, loading } = this.props;

        const {
            email,
            subject,
            subjectComplement,
            description,
        } = this.state;

        const margin = "dense" as PropTypes.Margin;

        const emailError = errors.find(x => x.fieldName === FormProps.Fields.email);
        const subjectCompError = errors.find(x => x.fieldName === FormProps.Fields.subjectComplement);
        const descriptionError = errors.find(x => x.fieldName === FormProps.Fields.description);

        // return (
        //     <>
        //         <div style={{ display: "flex", marginTop: 16, marginBottom: 16, justifyContent: "center", color: "#be2626" }}>
        //             <ErrorIcon />
        //         </div>
        //         <Typography component="p" style={{ marginTop: 8, marginBottom: 8 }}>
        //             <strong>Suite à un problème technique,</strong> le formulaire de contact est momentanément indisponible.
        //         </Typography>
        //         <Typography component="p" style={{ marginTop: 8, marginBottom: 8 }}>
        //             Pour nous contacter, <strong>veuillez nous écrire directement</strong> à <span style={{ color: "#be2626" }}>info@ferias40.fr</span>.
        //         </Typography>
        //         <div style={{ display: "flex", marginTop: 16, marginBottom: 16, justifyContent: "center" }}>
        //             <Button
        //                 href="mailto:info@ferias40.fr"
        //                 variant="contained"
        //                 color="primary"
        //             >
        //                 info@ferias40.fr

        //                 <span className={classes.iconBtn}>
        //                     <SendIcon />
        //                 </span>
        //             </Button>
        //         </div>
        //     </>
        // );

        return (
            <form onSubmit={this.handleSubmit} autoComplete="off">

                <Typography component="p" variant="caption" className={classes.note}>
                    Si vous souhaitez nous envoyer un document ou une image merci de nous écrire directement à <a href="mailto:info@ferias40.fr">info@ferias40.fr</a>
                </Typography>

                <TextField
                    required={true}
                    fullWidth={true}
                    margin={margin}
                    type="email"
                    label="Adresse e-mail"
                    name={FormProps.Fields.email}
                    error={emailError !== undefined}
                    helperText={emailError !== undefined ? emailError.message : undefined}
                    value={email}
                    onChange={this.handleInput}
                />

                <FormControl
                    required={true}
                    fullWidth={true}
                    margin={margin}
                    className={classes.selectControl}
                >
                    <InputLabel htmlFor={FormProps.Fields.subject}>Objet</InputLabel>
                    <Select
                        input={<Input name={FormProps.Fields.subject} id={FormProps.Fields.subject} />}
                        value={subject}
                        onChange={(e: any) => this.handleInput(e)}
                    >
                        {subjectList.map((s, idx) =>
                            <MenuItem value={s} key={idx}>{getSubjectLabel(s)}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <TextField
                    required={true}
                    fullWidth={true}
                    margin={margin}
                    label={getSubjectComplementLabel(subject)}
                    placeholder={getSubjectComplementPlaceholder(subject)}
                    name={FormProps.Fields.subjectComplement}
                    value={subjectComplement}
                    error={subjectCompError !== undefined}
                    helperText={subjectCompError !== undefined ? subjectCompError.message : undefined}
                    onChange={this.handleInput}
                />

                <TextField
                    required={true}
                    fullWidth={true}
                    margin={margin}
                    multiline={true}
                    rowsMax={8}
                    label="Description"
                    name={FormProps.Fields.description}
                    value={description}
                    error={descriptionError !== undefined}
                    helperText={descriptionError !== undefined ? descriptionError.message : undefined}
                    onChange={this.handleInput}
                />

                <Typography component="p" variant="caption" className={classes.note}>
                    * champs obligatoires
                </Typography>

                <div className={classes.actions}>
                    { extraAction }
                    <Spacer />
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                    >
                        Envoyer
                        <span className={classes.iconBtn}>
                            {loading ? <CircularProgress className={classes.loader} /> : <SendIcon />}
                        </span>
                    </Button>
                </div>

            </form>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(ContactForm);