import React from "react";
import ReactMarkdown from "react-markdown";
import { Guid } from "guid-typescript";

import { Typography } from "@material-ui/core";
import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import { apiRequestFactory, ApiRequestName } from "../../tools/api";

const TimelineTimeItemStyles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(0, 1, 1, 0),
        padding: theme.spacing(0, 1, 1, 1),
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        textDecoration: "none",
    },
    title: {
        display: "block",
    },
    description: {
        display: "block",
        color: fade(theme.palette.text.hint, 0.50),
        "& a": {
            color: fade(theme.palette.text.hint, 0.50),
            textDecoration: "underline",
        },
        "& p": {
            marginTop: 2,
            marginBottom: 2,
            wordWrap: "break-word",
        }
    },
    cover: {
        height: 120,
        [theme.breakpoints.down("sm")]: {
            height: 60,
        },
        width: "100%",
        marginBottom: 2,
        overflow: "hidden",
        backgroundAttachment: "scroll",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
});

interface TimelineEventProps {
    title: string,
    description?: string,
    coverId?: Guid,
}

type TimelineTimeItemStyledProps = WithStyles<typeof TimelineTimeItemStyles>;
export type TimelineTimeItemCombinedProps = TimelineEventProps & TimelineTimeItemStyledProps;

function TimelineTimeItem(props: TimelineTimeItemCombinedProps) {
    const { title, coverId, description, classes } = props;

    let coverPath = "";
    if (coverId) {
        coverPath = apiRequestFactory
            .get(ApiRequestName.ImageGet)
            .setParameters({ id: coverId.toString() })
            .fullPath();
    }

    return (
        <div className={classes.container}>

            <Typography className={classes.title} variant="body1" component="h4">
                {title}
            </Typography>

            {
                coverId
                    ? <div className={classes.cover} style={{ backgroundImage: `url(${coverPath})` }}></div>
                    : null
            }

            <Typography
                className={classes.description}
                component="div"
                variant="body2"
            >
                <ReactMarkdown
                    source={description}
                    renderers={{link: props => {
                        const href = props.href as string;
                        if (href.indexOf("mailto") === 0) {
                            return <a href={href}>{props.children}</a>
                        }
                        else {
                            return <a href={href} target="_blank" rel="noopener noreferrer">{props.children}</a>
                        }
                    }}}
                />
            </Typography>

        </div>
    )
}

export default withStyles(TimelineTimeItemStyles, { withTheme: true })(TimelineTimeItem);
