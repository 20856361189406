import React from "react";

import { MenubarDesktop as CMenubarDesktop } from "../../components/Menubar";

function MenubarDesktop() {
    return (
        <CMenubarDesktop
            title="CGU et mentions légales"
            subtitle="Informations sur les Conditions Générales d'Utilisation et mentions légales"
            collapseHidden={true}
        />
    );
}

export default MenubarDesktop;