import React from "react";
import { Guid } from "guid-typescript";

import { AppBar } from "@material-ui/core";

import MenubarDesktop from "./Menubar.desktop";
import ToolbarMobile from "./Toolbar.mobile";
import TimelineActivities from "./TimelineActivities";
import Container from "../../components/Container";
import { OnDesktop, OnMobile } from "../../components/Hidden";
import { MainToolbarDesktop, ToolbarSpacer } from "../../components/Toolbar";
import { MenubarSpacerMobile, MenubarSpacerDesktop } from "../../components/Menubar";
import TimelineMenubarMobile from "../../components/Menubar/TimelineMenubar.mobile";
import Datepicker from "../../components/Datepicker";
import Footer from "../../components/Footer";
import ActivityTypesFilterDialog from "../../components/ActivityTypesFilterDialog";
import { TimelineDayLoader } from "../../components/Timeline";

import { DayActivities, FilterableActivityType, Month } from "../../model/response";
import moment from "../../tools/moment";
import { TimelineObserver } from "../../tools/Timeline";

export interface Props {
    onOpenDrawer: (() => void),

    timelineMonths: Month[],

    timelineActivities: DayActivities[],
    timelineLoading: boolean,
    onUpdateTimelineTo: ((date: Date) => void),

    datepickerDays: Date[],

    filterableActivityTypes: FilterableActivityType[],
    selectedActivityiTypesId: Guid[],
    onFilterActivityTypes: ((activityiTypesId: Guid[]) => void),
}

export interface State {
    datepickerIsOpen: boolean,
    filterDialogIsOpen: boolean,
    datepickerMonth: string,
    datepickerDate: Date,
}

class TimelineActivitiesPage extends React.Component<Props, State> {

    _timelineRef: React.RefObject<HTMLDivElement>;
    _timelineObserver: TimelineObserver;
    _datepickerDateMax: Date;

    constructor(props: Props) {
        super(props);

        this._timelineRef = React.createRef();
        this._timelineObserver = new TimelineObserver(this._timelineRef, 
                ".timeline-month", ".timeline-day");

        const topMonth = this._timelineObserver.getTopMonth();
        const topDay = this._timelineObserver.getTopDay();

        this._datepickerDateMax = moment(topDay)
                .endOf("year")
                .toDate();

        this.state = {
            datepickerIsOpen: false,
            filterDialogIsOpen: false,
            datepickerMonth: topMonth,
            datepickerDate: topDay,
        };
    }

    componentDidMount() {
        document.title = "Calendrier des activités - Ferias40";

        window.scrollTo({ top: 0 }); // TODO change

        window.addEventListener("scroll", this.onScroll);
        this._timelineObserver.setDayElements(this.props.timelineActivities);
        this.updateDatepickerMonth();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    componentDidUpdate() {
        this._timelineObserver.setDayElements(this.props.timelineActivities);
        this.updateDatepickerMonth();
    }

    onScroll = () => {
        this.updateDatepickerMonth();
    }
    
    openDatepicker = () => {
        const topDay = this._timelineObserver.getTopDay();
        this.setState({ datepickerDate: topDay }, () => this.setState({ datepickerIsOpen: true }));
    }
    closeDatepicker = () => {
        this.setState({ datepickerIsOpen: false });
    }
    handleDatePicker = (date: Date): void => {
        this.setState({ datepickerIsOpen: false }, async () => {
            const mDate = this._timelineObserver.getValidTimelineToday(date);
            const id = mDate.format("YYYY-MM-DD");
            let elt = this._timelineObserver.findDayElement(id);
            if (!elt) {
                await this.props.onUpdateTimelineTo(date);
                elt = this._timelineObserver.findDayElement(id);
            }
            if (elt) {
                this._timelineObserver.scrollTo(elt);
            }
        });
    }
    updateDatepickerMonth = () => {
        const topMonth = this._timelineObserver.getTopMonth();
        if (topMonth !== this.state.datepickerMonth) {
            this.setState({ datepickerMonth: topMonth });
        }
    }


    openFilterDialog = () => {
        this.setState({ filterDialogIsOpen: true });
    }
    closeFilterDialog = () => {
        this.setState({ filterDialogIsOpen: false });
    }
    handleFilterChange = (ids: Guid[]) => {
        this.closeFilterDialog();
        this.props.onFilterActivityTypes(ids);
    }

    render() {

        const {
            onOpenDrawer, timelineMonths,
            timelineLoading, timelineActivities,
            datepickerDays,
            filterableActivityTypes, selectedActivityiTypesId,
        } = this.props;

        const {
            datepickerIsOpen, datepickerMonth, datepickerDate,
            filterDialogIsOpen,
        } = this.state;

        return (
            <React.Fragment>

                <AppBar position="fixed">

                    <OnDesktop>
                        <MainToolbarDesktop />
                        <MenubarDesktop
                            onDatePicker={this.openDatepicker}
                            datepickerLabel={datepickerMonth}
                            onActivityTypesFilter={this.openFilterDialog}
                        />
                    </OnDesktop>

                    <OnMobile>
                        <ToolbarMobile
                            onOpenDrawer={onOpenDrawer}
                            onDatePicker={this.openDatepicker}
                            datepickerLabel={datepickerMonth}
                            onActivityTypesFilter={this.openFilterDialog}
                        />
                        <TimelineMenubarMobile />
                    </OnMobile>

                </AppBar>

                <Container>

                    <ToolbarSpacer />

                    <OnDesktop>
                        <MenubarSpacerDesktop />
                    </OnDesktop>

                    <OnMobile>
                        <MenubarSpacerMobile />
                    </OnMobile>

                    <div ref={this._timelineRef}>

                        <TimelineActivities
                            timelineMonths={timelineMonths}
                            dayActivities={timelineActivities}
                        />

                        {timelineLoading
                            ? <TimelineDayLoader />
                            : <React.Fragment></React.Fragment>
                        }

                    </div>

                    <OnDesktop>
                        <Footer />
                    </OnDesktop>

                </Container>

                <Datepicker
                    isOpen={datepickerIsOpen}
                    value={datepickerDate}
                    onClose={this.closeDatepicker}
                    onChange={this.handleDatePicker}
                    maxDate={this._datepickerDateMax}
                    timelineDays={datepickerDays}
                    format="MMMM"
                    style={{ display: "none" }}
                />

                <ActivityTypesFilterDialog
                    open={filterDialogIsOpen}
                    onClose={this.closeFilterDialog}
                    activityTypes={filterableActivityTypes}
                    selectedActivityTypesId={selectedActivityiTypesId}
                    onSelectionChange={this.handleFilterChange}
                />

            </React.Fragment>
        );
    }
}

export default TimelineActivitiesPage;