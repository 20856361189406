import React from "react";
import { Guid } from "guid-typescript";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import { AppBar, IconButton, Typography } from "@material-ui/core";

import ContactForm from ".";
import { FormProps } from "./Types";
import Dialog from "../Dialog";
import { ToolbarMobile } from "../Toolbar";
import { MenubarMobile, MenubarSpacerMobile } from "../Menubar";
import Container from "../Container";
import { ContactIcon, SendIcon, ArrowLeftIcon } from "../../App";

const Styles = (theme: Theme) => createStyles({
    dialogPaper: {
        margin: 0,
    },
    senbBtn: {
        backgroundColor: theme.palette.primary.dark,
    },
    menubar: {
        padding: theme.spacing(1, 3),
        flexDirection: "column",
        justifyContent: "space-around",
    },
});

export interface Props {
    eventId?: Guid,
    initialValue?: FormProps,
    onAfterSend?: (() => void),
    open: boolean,
    onClose: (() => void),
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function ContactFormDialogMobile(props: CombinedProps) {

    const { classes, initialValue, eventId, open, onClose, onAfterSend } = props;

    return (
        <Dialog
            fullScreen={true}
            hideActions={true}
            id="event-contact-info-dialog"
            open={open}
            onClose={onClose}
            title="Besoin d'un renseignement ?"
            titleIcon={<ContactIcon />}
            actionLabel="Envoyer"
            actionIcon={<SendIcon />}
            classes={{
                dialogPaper: classes.dialogPaper,
            }}
        >
            <AppBar position="fixed">

                <ToolbarMobile>

                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={onClose}
                    >
                        <ArrowLeftIcon />
                    </IconButton>

                    <Typography variant="h6" component="h3">
                        Contact
                    </Typography>

                </ToolbarMobile>

                <MenubarMobile
                    classes={{
                        rowCollapse: classes.menubar
                    }}
                >
                    <Typography variant="body2" component="span">
                        Besoins de renseignements ?
                    </Typography>
                </MenubarMobile>

            </AppBar>

            <Container>

                <MenubarSpacerMobile />

                <ContactForm
                    eventId={eventId}
                    initialValue={initialValue}
                    onAfterSend={onAfterSend}
                />

            </Container>

        </Dialog>
    );
}

export default withStyles(Styles, { withTheme: true })(ContactFormDialogMobile);