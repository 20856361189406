import moment, { Moment } from "moment";
import "moment/locale/fr";

moment.locale("fr");

export default moment;

const dateHashFormat = "dddd-D";

function getDateHashParts(hash: string|undefined): { day: string, time: string | undefined } {
    let day = "";
    let time = undefined;
    if (hash && hash.length > 0) {
        const hashParts = hash.split("_");
        day = hashParts[0];
        time = hashParts[1] ? hashParts[1] : undefined;
    }
    return { day, time };
}

function numberOfDaysBetween(d1: Date | Moment, d2: Date | Moment): number {
    let mD1 = moment(d1);
    let mD2 = moment(d2);

    return Math.abs(mD1.diff(mD2, "days"));
}

function getMonth(d1: Date | Moment): number {
    return moment(d1).month();
}

function numberOfMonthBetween(d1: Date | Moment, d2: Date | Moment): number {
    return Math.abs(getMonth(d1) - getMonth(d2));
}

function numberOfHoursBetween(t1: string, t2: string): number {
    const h1 = parseInt(t1.split(":")[0], 10);
    const h2 = parseInt(t2.split(":")[0], 10);

    return Math.abs(h2 - h1) % 23;
}

function isTimeInside(time: string, startTime: string, endTime: string): boolean {
    if (time === startTime) return true;
    
    let mTime = moment(`1986-12-09 ${time}:00`);
    let mStart = moment(`1986-12-09 ${startTime}:00`);
    let mEnd = moment(`1986-12-09 ${endTime}:00`);

    if (mTime.hours() < 6) mTime = mTime.add(1, "days");
    if (mStart.hours() < 6) mStart = mStart.add(1, "days");
    if (mEnd.hours() < 6) mEnd = mEnd.add(1, "days");

    return mTime.isBetween(mStart, mEnd);
}

function getDatesRangeLabel(
    startAt: Date, 
    endAt: Date, 
    displayYear: boolean = false,
    dayFormat:string = "dddd D"): string {

    const mStartAt = moment(startAt);
    const mEndAt = moment(endAt);
    const startDay = mStartAt.format(dayFormat);
    const startMonth = mStartAt.format("MMMM");
    const endDay = mEndAt.format(dayFormat);
    const endMonth = mEndAt.format("MMMM");

    const year = displayYear ? mEndAt.format(" YYYY") : "";

    if (mStartAt.isSame(mEndAt)) {
        return `le ${startDay} ${startMonth}${year}`;
    }

    if (startMonth === endMonth) {
        return `du ${startDay} au ${endDay} ${endMonth}${year}`;
    }
    
    return `du ${startDay} ${startMonth} au ${endDay} ${endMonth}${year}`;
}

export {
    dateHashFormat,
    getDateHashParts,
    getMonth,
    numberOfDaysBetween,
    numberOfMonthBetween,
    numberOfHoursBetween,
    isTimeInside,
    getDatesRangeLabel,
};