import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import ToolbarDesktop from "./Toolbar.desktop";

const ToolbarMobileStyles = (theme: Theme) => createStyles({
    containerContainer: {
        padding: theme.spacing(0, 1, 0, 1),
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            margin: 0,
        },
    },
});

export interface ToolbarMobileProps {
    children: React.ReactElement | React.ReactElement[],
}

type ToolbarMobileStyledProps = WithStyles<typeof ToolbarMobileStyles>;
export type ToolbarMobileCombinedProps = ToolbarMobileProps & ToolbarMobileStyledProps;

function ToolbarMobile(props: ToolbarMobileCombinedProps) {

    const { children, classes } = props;

    return (
        <ToolbarDesktop
            classes={{
                containerContainer: classes.containerContainer,
            }}
        >
                {children}

        </ToolbarDesktop>
    );
}

export default withStyles(ToolbarMobileStyles, { withTheme: true })(ToolbarMobile);