import React from "react";
import { Guid } from "guid-typescript";

import ContactForm from "./ContactForm";

import { Contact as CContact } from "../../model/command";
import { FormError } from "../../model/response";
import { Subject, FormProps, getSubjectLabel } from "./Types";
import { apiRequestFactory, ApiRequestName } from "../../tools/api";

export interface Props {
    eventId?: Guid,
    initialValue?: FormProps,
    extraAction?: React.ReactElement,
    onAfterSend?: (() => void),
}

export interface State {
    initialValue?: FormProps,
    formErrors: FormError[],
    loading: boolean,
}

class ContactFormContainer extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            initialValue: props.initialValue,
            formErrors: [],
            loading: false,
        }
    }

    handleSubmit = async (formProps: FormProps) => {

        this.setState({ loading: true });

        let subject = formProps.subjectComplement;
        if (formProps.subject !== Subject.Other) {
            subject = getSubjectLabel(formProps.subject) + " : " + subject;
        }
        const contact = {
            eventId: this.props.eventId,
            from: formProps.email,
            subject,
            body: formProps.description,
        } as CContact;

        try {
            await apiRequestFactory
                .get(ApiRequestName.Contact)
                .setBody(contact)
                .request();

            this.setState({
                formErrors: [],
                initialValue: this.props.initialValue,
                loading: false,
            }, () => {
                if (this.props.onAfterSend) {
                    this.props.onAfterSend();
                }
            });
        }
        catch (error) {
            const formErrors = error.response.data as FormError[]
            this.setState({ initialValue: formProps, formErrors, loading: false });
        }
    }

    render() {

        const { extraAction } = this.props;

        const { initialValue, formErrors, loading } = this.state;

        return (
            <ContactForm
                initialValue={initialValue}
                errors={formErrors}
                onSubmit={this.handleSubmit}
                extraAction={extraAction}
                loading={loading}
            />
        );
    }
}

export default ContactFormContainer;