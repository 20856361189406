import React from "react";
import classnames from "classnames";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    Dialog as MuiDialog,
    DialogTitle, DialogContent, DialogActions,
    Button,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import Spacer from "../Spacer";
import { TransitionProps } from "@material-ui/core/transitions";

const Styles = (theme: Theme) => createStyles({
    dialogPaper: {
        margin: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(1),
        },
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(1, 2),
    },
    action: {
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
    title: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "left",
        "& svg": {
            marginRight: theme.spacing(2),
            pointerEvents: "none",
        },
    },
    hide: {
        display: "none",
    }
});

export interface Props {
    id: string,
    title: string,
    titleIcon?: React.ReactElement<SvgIconProps>,
    actionLabel?: string,
    actionIcon?: React.ReactElement<SvgIconProps>,
    onAction?: (() => void),
    onClose: (() => void),
    open: boolean,
    fullScreen?: boolean,
    TransitionComponent?: React.ComponentClass<TransitionProps, any> | React.FunctionComponent<TransitionProps>,
    hideActions?: boolean,
    children?: React.ReactElement | React.ReactElement[],
}

type StyledProps = WithStyles<typeof Styles>;
type CombinedProps = StyledProps & Props;

function Dialog(props: CombinedProps) {

    const {
        classes,
        id, open, onClose, title, titleIcon,
        actionLabel, actionIcon, onAction,
        fullScreen, TransitionComponent, hideActions,
        children,
    } = props;

    return (
        <MuiDialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby={id}
            open={open}
            onClose={onClose}
            TransitionComponent={TransitionComponent}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogTitle id={id} className={classes.dialogTitle}>
                <div className={classes.title}>
                    {titleIcon}
                    {title}
                </div>
            </DialogTitle>

            <DialogContent dividers className={classes.dialogContent}>

                {children}

            </DialogContent>

            <DialogActions className={classnames(classes.dialogActions, hideActions === true ? classes.hide : undefined)}>
                <Button onClick={onClose} color="primary" variant="contained">
                    Fermer
                </Button>

                <Spacer />

                <Button onClick={onAction} color="primary" variant="contained" className={classes.action}>
                    {actionIcon}
                    {actionLabel}
                </Button>
            </DialogActions>

        </MuiDialog>

    );

}

export default withStyles(Styles, { withTheme: true })(Dialog);