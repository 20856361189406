import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

const CornerStyles = (theme: Theme) => createStyles({
    container: {
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        bottom: theme.spacing(5),
        right: theme.spacing(3),
        zIndex: theme.zIndex.modal,
        "& > *": {
            marginTop: theme.spacing(1)
        },
        [theme.breakpoints.down("sm")]: {
            bottom: theme.spacing(3),
            right: theme.spacing(2),
        },
    },
});


export interface CornerProps {
    children: React.ReactElement | (React.ReactElement)[],
}

type CornerStyledProps = WithStyles<typeof CornerStyles>;
export type CornerCombinedProps = CornerProps & CornerStyledProps;

function Corner(props: CornerCombinedProps) {

    const { classes, children } = props;

    return (
        <div className={classes.container}>
            {children}
        </div>
    );
}

export default withStyles(CornerStyles, { withTheme: true })(Corner);
