import React from "react";

import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import { AppBar, CircularProgress } from "@material-ui/core";

import EventProgram from "./EventProgram";
import MenubarDesktop from "./Menubar.desktop";
import ToolbarMobile from "./Toolbar.mobile";
import MenubarMobile from "./Menubar.mobile";
import Container from "../../components/Container";
import { OnDesktop, OnMobile } from "../../components/Hidden";
import { MainToolbarDesktop, ToolbarSpacer } from "../../components/Toolbar";
import { MenubarSpacerDesktop, MenubarSpacerMobile } from "../../components/Menubar";
import Footer from "../../components/Footer";
import moment from "../../tools/moment";

import { EventDay, Event } from "../../model/response";
import { ContactFormPaper, FormProps } from "../../components/ContactForm";
import { Subject } from "../../components/ContactForm";
import Snackbar from "../../components/Snackbar";

const Styles = (theme: Theme) => createStyles({
    containerContainer: {
        maxWidth: 750,
    },
    loading: {
        display: "block",
        marginTop: theme.spacing(6),
        marginLeft: "auto",
        marginRight: "auto",
    },
});

export interface Props {
    loading: boolean,
    eventProgram?: Event,
    eventDay?: EventDay,
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

export interface State {
    confirmationIsOpen: boolean,
}

class EventProgramPage extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);

        this.state = {
            confirmationIsOpen: false,
        }
    }

    componentDidMount() {
        this.updateTitle(this.props);
    }

    componentWillReceiveProps(nextProps:CombinedProps) {
        this.updateTitle(nextProps);
    }

    updateTitle = (props:CombinedProps) => {
        const { loading, eventProgram, eventDay } = props;

        if (loading) {
            document.title = "Chargement ... | Ferias40";
        }
        else {
            if (eventProgram && eventDay) {
                const date = moment(eventDay.date).format("dddd D MMMM YYYY");
                document.title = `${eventProgram.townName}, ${eventProgram.eventTypeName} du ${date} - Ferias40`;
            }
        }
    }

    openConfirmation = () => this.setState({ confirmationIsOpen: true });
    closeConfirmation = () => this.setState({ confirmationIsOpen: false });

    render() {

        const { classes, loading, eventProgram, eventDay } = this.props;
        const { confirmationIsOpen } = this.state;

        return (
            <React.Fragment>

                <AppBar position="fixed">

                    <OnDesktop>
                        <MainToolbarDesktop />
                        <MenubarDesktop
                            loading={loading}
                            eventProgram={eventProgram}
                        />
                    </OnDesktop>

                    <OnMobile>
                        <ToolbarMobile
                            loading={loading}
                            eventProgram={eventProgram}
                        />
                        <MenubarMobile
                            loading={loading}
                            eventProgram={eventProgram}
                        />
                    </OnMobile>

                </AppBar>

                <Container
                    classes={{
                        container: classes.containerContainer,
                    }}
                >

                    <ToolbarSpacer />

                    <OnDesktop>
                        <MenubarSpacerDesktop />
                    </OnDesktop>

                    <OnMobile>
                        <MenubarSpacerMobile />
                    </OnMobile>

                    {loading
                        ? <div className={classes.loading}><CircularProgress /> </div>
                        : <React.Fragment></React.Fragment>
                    }

                    {!loading && eventProgram && !eventProgram.areDatesValidated
                        ?
                        <ContactFormPaper
                            initialValue={{
                                subject: Subject.ValidateEventDates,
                                subjectComplement: `${eventProgram.eventTypeName} de ${eventProgram.townName}`,
                            } as FormProps}
                            onAfterSend={this.openConfirmation}
                        >
                            <p>
                                Les dates des {eventProgram.eventTypeName} de {eventProgram.townName} ne sont pas encore validées.
                            </p>
                            <p>
                                Si vous disposez d'informations, n'hésitez pas à nous contacter via le 
                                formulaire ci-dessous en renseignant votre email et en indiquant les dates valides. 
                            </p>
                            <p>
                                Nous nous chargerons de la mise à jour dans les meilleurs délais.
                            </p>
                        </ContactFormPaper>
                        :
                        <React.Fragment></React.Fragment>
                    }


                    {!loading && eventProgram && eventProgram.areDatesValidated && eventDay && eventDay.activities.length === 0
                        ?
                        <ContactFormPaper
                            initialValue={{
                                subject: Subject.SetEventProgram,
                                subjectComplement: `${eventProgram.eventTypeName} de ${eventProgram.townName}`,
                            } as FormProps}
                            onAfterSend={this.openConfirmation}
                        >
                            <p>
                                Le programme des {eventProgram.eventTypeName} de {eventProgram.townName} n'est pas disponible pour le
                                {moment(eventDay.date).format(" dddd D MMMM.")}
                            </p>
                            <p>
                                Si vous disposez d'informations, n'hésitez pas à nous contacter via le 
                                formulaire ci-dessous en renseignant votre email et en décrivant le programme.
                            </p>
                            <p>
                                Nous nous chargerons de la mise à jour dans les meilleurs délais.
                            </p>
                        </ContactFormPaper>
                        :
                        <React.Fragment></React.Fragment>
                    }

                    {!loading && eventProgram && eventProgram.areDatesValidated && eventDay && eventDay.activities.length > 0
                        ?
                        <EventProgram
                            eventProgram={eventProgram}
                            eventDay={eventDay}
                        />
                        :
                        <React.Fragment></React.Fragment>
                    }

                    <OnDesktop>
                        <Footer />
                    </OnDesktop>

                    <Snackbar
                        open={confirmationIsOpen}
                        onClose={this.closeConfirmation}
                        message="Votre demande a bien envoyée"
                    />

                </Container>

            </React.Fragment>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(EventProgramPage);