import React from "react";

import { createStyles, Theme, fade } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import {
    IconButton,
} from "@material-ui/core";


import { ToolbarMobile as CToolbarMobile } from "../../components/Toolbar";
import Spacer from "../../components/Spacer";
import ButtonIcon from "../../components/ButtonIcon";
import {
    MenuIcon,
    ArrowDownIcon, FilterIcon,
} from "../../App/App.theme";

const Styles = (theme: Theme) => createStyles({
    monthBtn: {
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: fade(theme.palette.common.white, 0.90),
        textTransform: "capitalize",
        fontSize: theme.typography.h6.fontSize,
        width: "auto",
        "&:hover": {
            color: fade(theme.palette.common.white, 1),
        },
    },
    menuIcon: {
    },
})

export interface Props {
    onOpenDrawer: (() => void),
    onDatePicker: (() => void),
    datepickerLabel: string,
    onActivityTypesFilter: (() => void),
}

type StyledProps = WithStyles<typeof Styles>;
export type CombinedProps = Props & StyledProps;

function ToolbarMobile(props: CombinedProps) {

    const {
        classes, onDatePicker, datepickerLabel,
        onOpenDrawer, onActivityTypesFilter
    } = props;


    return (
        <CToolbarMobile>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onOpenDrawer}
            >
                <MenuIcon />
            </IconButton>

            <ButtonIcon
                rightIcon={<ArrowDownIcon />}
                onClick={onDatePicker}
                classes={{ button: classes.monthBtn }}
            >
                {datepickerLabel}
            </ButtonIcon>

            <Spacer />

            <IconButton
                color="inherit"
                aria-label="Filter activity type"
                onClick={onActivityTypesFilter}
            >
                <FilterIcon />
            </IconButton>

        </CToolbarMobile>

    );
}

export default withStyles(Styles, { withTheme: true })(ToolbarMobile);
